import React from "react";
import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import axiosJSON from "../../util/SetHeaderAPI";
import PositionsList from "./list/index";
import AppModuleHeader from "../../components/AppModuleHeader";
import AddEditPositions from "../../modals/Positions/addEditPositions";
import PositionDetails from "../../modals/Positions/positionDetails";
import CircularProgress from "../../components/CircularProgress";
import {
  Button,
  Checkbox,
  Drawer,
  message,
  Spin,
  Pagination,
  Select,
} from "antd";
import { connect } from "react-redux";
import "nprogress/nprogress.css";
import nprogress from "nprogress";
import { CustomModal as Modal } from "../../util/CustomModal";

class Positions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerState: false,
      showAddPosition: false,
      viewPosition: false,
      query: "",
      positionslist: [],
      loader: false,
      total_pages: 1,
      filters: {},
      filterObj: {},
      openFilterOption: [false],
      filterList: [
        {
          name: "Status",
          value: "status",
        },
      ],
      selectedPosition: {},
      prevY: 0,
      page: 1,
    };
    this.Mounted = false;
  }

  onToggleDrawer() {
    this.setState({
      drawerState: !this.state.drawerState,
    });
  }

  handleclose = () => {
    this.setState(
      {
        showAddPosition: false,
        viewPosition: false,
        positionslist: [],
        total_pages: 1,
        page: 1,
      },
      () => this.updateCallback(1, [])
    );
  };

  getFilters = () => {
    axiosJSON
      .get("/api/position/filter/")
      .then(({ data, status }) => {
        if (status === 200) {
          this.setState({
            filters: data.data,
          });
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  selectLocation = (value) => {
    if (!value) {
      this.selectFilter(false, value, "location");
    } else this.selectFilter(true, value, "location");
  };

  filterMenu = (filter) => {
    var filterList = Object.keys(this.state.filters);
    var options;
    filterList.map((ele, i) => {
      if (ele === filter) {
        options = this.state.filters[ele].map((filterObj, j) => (
          <div key={j}>
            <Checkbox
              checked={
                this.state.filterObj.hasOwnProperty(filter) &&
                this.state.filterObj[filter].includes(filterObj)
              }
              onChange={(e) =>
                this.selectFilter(e.target.checked, filterObj, filter)
              }
            />
            {filterObj}
          </div>
        ));
      }
      return null;
    });
    return options;
  };

  selectFilter = (checked, obj, type) => {
    let filterObj = this.state.filterObj;
    let o = this.state.filterObj;
    if (checked) {
      if (o.hasOwnProperty(type)) {
        if (type === "location") {
          o[type] = [obj];
        } else {
          o[type].push(obj);
        }
      } else {
        o[type] = [obj];
        filterObj = o;
      }
    } else {
      if (o.hasOwnProperty(type)) {
        if (type === "location") {
          delete o[type];
        } else {
          var index = o[type].indexOf(obj);
          if (index !== -1) o[type].splice(index, 1);
          if (o[type].length === 0) delete o[type];
        }
      }
    }

    this.setState(
      {
        filterObj: filterObj,
        jobsList: [],
        page: 1,
        total_pages: 1,
      },
      () => this.updateCallback(this.state.page, [])
    );
  };

  updateSearch(evt) {
    evt.preventDefault();
    this.setState(
      {
        query: evt.target.value,
        positionslist: [],
        total_pages: 1,
        page: 1,
      },
      () => this.updateCallback(this.state.page, [])
    );
  }

  componentDidMount() {
    nprogress.start();
    this.Mounted = true;
    this.getPositionsList(this.state.page);
    this.getFilters();
    this.getCities("");
    nprogress.done();
  }

  componentWillUnmount() {
    this.Mounted = false;
  }

  getPositionsList = (page) => {
    this.updateCallback(page, this.state.positionslist);
  };

  toggleFilterOption = (i) => {
    let openFilterOption = this.state.openFilterOption;
    openFilterOption[i] = !this.state.openFilterOption[i];
    this.setState({
      openFilterOption,
    });
  };
  getCities = (query) => {
    axiosJSON
      .get("/api/city/?query=" + query)
      .then(({ data, status }) => {
        this.setState({
          cityArr: data.data,
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  updateCallback = (page, arr) => {
    this.setState({ loader: true });
    axiosJSON
      .get(
        "/api/position/?page=" +
          page +
          "&query=" +
          this.state.query +
          "&filter_json=" +
          JSON.stringify(this.state.filterObj)
      )
      .then(({ data, status }) => {
        if (status === 200) {
          this.setState({});
          // let positionslist = arr;
          let positionslist = [];
          let positionObj = {};

          data.data.map((ele, i) => {
            positionObj = {
              id: ele.id,
              title: ele.title,
              location: ele.location,
              status: ele.status,
              created_by: ele.created_by,
              description: ele.description,
              created: ele.created,
              modified: ele.modified,
            };
            positionslist.push(positionObj);
            return null;
          });
          this.setState({
            total_pages: data.total,
            positionslist: positionslist,
            loader: false,
          });
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  selectPosition = (item) => {
    this.setState({
      viewPosition: true,
      selectedPosition: item,
    });
  };

  ToDoSideBar = () => {
    return (
      <div className="gx-module-side">
        <div className="gx-module-side-header">
          <div className="gx-modul-logo">
            <i className="icon icon-plain-list-divider gx-mr-4" />
            <IntlMessages id="sidebar.positions" />
          </div>
        </div>
        <div className="gx-module-side-content">
          <CustomScrollbars className="">
            {console.log("--->>",this.props.accessData)}
            <div className="gx-module-add-task">
              {this.props.accessData.create_position && (
                <Button
                  variant="raised"
                  type="primary"
                  onClick={() => this.setState({ showAddPosition: true })}
                  className="gx-btn-block"
                >
                  {" "}
                  Add Job Positions
                </Button>
              )}
            </div>
            <ul className="gx-module-nav new-side-nav">
              <li className="gx-module-nav-label">
                <IntlMessages id="candidate.filters" />
                {this.state.filterList.map((filter, i) => (
                  <div className="sidebar_new" key={i}>
                    {filter.name}
                    {this.state.openFilterOption[i] ? (
                      <i
                        className="icon icon-menu-up gx-pointer"
                        onClick={() => this.toggleFilterOption(i)}
                      />
                    ) : (
                      <i
                        className="icon icon-menu-down gx-pointer"
                        onClick={() => this.toggleFilterOption(i)}
                      />
                    )}
                    {this.state.openFilterOption[i] && (
                      <div>{this.filterMenu(filter.value)}</div>
                    )}
                  </div>
                ))}
                <div
                  className="sidebar_new"
                  onClick={() => this.toggleFilterOption(8)}
                >
                  Location <i className="icon icon-menu-down" />
                  <div>
                    <Select
                      showSearch
                      allowClear={true}
                      value={
                        this.state.filterObj.hasOwnProperty("location")
                          ? this.state.filterObj["location"]
                          : []
                      }
                      // mode="multiple"
                      style={{ width: "100%" }}
                      placeholder="Select Location"
                      onChange={(value) => this.selectLocation(value)}
                      onSearch={this.getCities}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.cityArr?.map((city, i) => (
                        <Select.Option
                          key={city.id}
                          value={city.name + ", " + city.state}
                        >
                          {city.name + ",  " + city.state}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </li>
            </ul>
          </CustomScrollbars>
        </div>
      </div>
    );
  };

  render() {
    const {
      drawerState,
      positionslist,
      loader,
      showAddPosition,
      selectedPosition,
      viewPosition,
    } = this.state;
    const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
    return (
      <div className="gx-main-content">
        <div className="gx-app-module">
          <div className="gx-d-block gx-d-lg-none">
            <Drawer
              placement="left"
              closable={false}
              visible={drawerState}
              onClose={this.onToggleDrawer.bind(this)}
            >
              {this.ToDoSideBar()}
            </Drawer>
          </div>
          <div className="gx-module-sidenav gx-d-none gx-d-lg-flex">
            {this.ToDoSideBar()}
          </div>
          <div className="gx-module-box">
            <div className="gx-module-box-header">
              <span className="gx-drawer-btn gx-d-flex gx-d-lg-none">
                <i
                  className="icon icon-menu gx-icon-btn"
                  aria-label="Menu"
                  onClick={this.onToggleDrawer.bind(this)}
                />
              </span>
              <AppModuleHeader
                placeholder="Search Positions"
                onChange={this.updateSearch.bind(this)}
                value={this.state.query}
              />
            </div>
            <div className="gx-module-box-content">
              <div style={{ height: "10px" }}></div>
              {loader ? (
                <CircularProgress />
              ) : (
                <PositionsList
                  positionsList={positionslist}
                  selectPosition={this.selectPosition}
                  getCall={this.updateCallback}
                />
              )}
              {positionslist.length > 0 ? (
                <span style={loadingTextCSS}>
                  <Spin />
                  Loading...
                </span>
              ) : (
                <span style={{ textAlign: "center" }}>No records found.</span>
              )}
              <div className="Pagination-section">
                <Pagination
                  // simple
                  total={this.state.total_pages}
                  current={this.state.page}
                  onChange={(page) =>
                    this.setState({ page: page }, () =>
                      this.getPositionsList(this.state.page)
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
        {showAddPosition && (
          <Modal
            title="Add Position"
            visible={showAddPosition}
            footer={null}
            onCancel={() => this.handleclose()}
          >
            <AddEditPositions
              edit={false}
              closeModal={() => this.handleclose()}
            />
          </Modal>
        )}
        {viewPosition && (
          <Modal
            title="Position Details"
            className="popupsize"
            visible={viewPosition}
            footer={null}
            onCancel={() => this.handleclose()}
          >
            <PositionDetails
              data={selectedPosition}
              closeModal={() => this.handleclose()}
            />
          </Modal>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  accessData: state.auth.accessData,
});

export default connect(mapStateToProps)(Positions);
