import React from "react";
import { Button, Input, Radio, DatePicker, message, Select } from "antd";
import moment from "moment";
import axiosJSON from "../../util/SetHeaderAPI";
import { connect } from "react-redux";

class EditProfile extends React.Component {
  state = {
    legal_name: "",
    name: "",
    dob: "",
    gender: "",
    currentLocation: "",
    locationPreference: "",
    position: null,
    cityArr: [],
    positionArr: [],
    ssn: "",
    rate: 0,
    country: null,
    countryList: [],
    maritalStatus: "",
  };

  Mounted = false;

  componentWillUnmount() {
    this.Mounted = false;
  }

  componentDidMount() {
    this.Mounted = true;
    this.getData(this.props.id);
    this.getCities("");
    this.getPosition();
    this.getCountryList();
    if (this.props.data) {
      this.setState({
        legal_name: this.props.data.legal_name,
        name: this.props.data.name,
        maritalStatus: this.props.data.marital_status,
      });
    }
  }

  getCountryList = () => {
    axiosJSON
      .get("api/choice/?type=country")
      .then(({ data, status }) => {
        if (status === 200) {
          let countries = [];
          data.data.map((position) => {
            countries.push(position);
            return null;
          });
          this.setState({
            countryList: countries,
          });
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  onMarraigeChange = (event) => {
    this.setState({ maritalStatus: event.target.value });
  };

  getData = (id) => {
    axiosJSON
      .get("api/candidate/" + id + "/about/")
      .then(({ data, status }) => {
        if (status === 200) {
          this.setState({
            currentLocation: data.data.current_location
              ? data.data.current_location
              : "",
            gender: data.data.gender,
            locationPreference: !data.data.location_preference
              ? ""
              : data.data.location_preference,
            position: data.data.position ? data.data.position.id : "",
            dob: data.data.dob ? moment(data.data.dob) : new Date(),
            ssn: data.data.ssn ? data.data.ssn : "",
            rate: data.data.rate ? data.data.rate : 0,
            country: data.data.country ? data.data.country : null,
            maritalStatus: data.data.marital_status
              ? data.data.marital_status
              : null,
          });
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  onSubmit = () => {
    const body = {
      legal_name: this.state.legal_name,
      name: this.state.name,
      gender: this.state.gender,
      dob: moment(this.state.dob).format("YYYY-MM-DD"),
      current_location: this.state.currentLocation,
      location_preference: this.state.locationPreference,
      ssn: this.state.ssn,
      rate: parseInt(this.state.rate),
      job_position: this.state.position,
      country: this.state.country,
      marital_status: this.state.maritalStatus,
    };
    axiosJSON
      .put("api/candidate/" + this.props.data.id + "/", body)
      .then(({ data, status }) => {
        if (status === 202) {
          message.success(data.message);
          this.props.closeModal();
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  handleChange = (e) => {
    if (e.target.name === "rate" && !isNaN(e.target.value)) {
      this.setState({
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name !== "rate") {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  onhandleSelect = (value, type) => {
    this.setState({
      [type]: value,
    });
  };

  onhandleSelectPosition = (value) => {
    this.setState({
      position: value,
    });
  };
  getCities = (query) => {
    axiosJSON
      .get("/api/city/?query=" + query)
      .then(({ data, status }) => {
        this.setState({
          cityArr: data.data,
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  getPosition = () => {
    axiosJSON
      .get("/api/position/dropdown/")
      .then(({ data, status }) => {
        this.setState({
          positionArr: data.data,
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  onDobChange = (date, dateString) => {
    this.setState({
      dob: dateString,
    });
  };

  render() {
    const {
      legal_name,
      name,
      dob,
      gender,
      locationPreference,
      currentLocation,
      position,
      ssn,
      rate,
      country,
      maritalStatus,
    } = this.state;
    return (
      <div className="gx-login-container">
        <div className="edit_popup_form">
          <span>
            Legal Name
            <Input
              className="gx-input-lineheight"
              value={legal_name}
              name="legal_name"
              onChange={this.handleChange}
              placeholder="Legal Name"
            />
          </span>

          <span>
            {" "}
            Name
            <Input
              className="gx-input-lineheight"
              value={name}
              name="name"
              onChange={this.handleChange}
              placeholder="Name"
            />
          </span>
          <span className="location-section">
            Preferred Location
            <Select
              showSearch
              style={{ width: "100%" }}
              className={"selectCorrection"}
              placeholder={"Preferred Location"}
              value={locationPreference}
              onSearch={this.getCities}
              onSelect={(value) =>
                this.onhandleSelect(value, "locationPreference")
              }
            >
              {this.state.cityArr.map((ele, i) => (
                <Select.Option key={i} value={ele.name + ", " + ele.state}>
                  {ele.name + ", " + ele.state}
                </Select.Option>
              ))}
            </Select>
          </span>
          <span className="location-section">
            Country
            <br />
            <Select
              style={{ width: "100%" }}
              className={"selectCorrection"}
              placeholder={"Current Country"}
              value={country}
              onSelect={(value) => this.onhandleSelect(value, "country")}
            >
              {this.state.countryList.map((ele, i) => (
                <Select.Option key={i} value={ele.display_name}>
                  {ele.display_name}
                </Select.Option>
              ))}
            </Select>
          </span>

          <span className="current-section">
            <label>
              {" "}
              Current Location
              <Select
                showSearch
                style={{ width: "150px" }}
                value={currentLocation}
                onSearch={this.getCities}
                onSelect={(value) =>
                  this.onhandleSelect(value, "currentLocation")
                }
              >
                {this.state.cityArr.map((ele, i) => (
                  <Select.Option key={i} value={ele.name + ", " + ele.state}>
                    {ele.name + ", " + ele.state}
                  </Select.Option>
                ))}
              </Select>
            </label>
          </span>

          <span className="current-section">
            <label>
              {" "}
              Position
              {
                <Select
                  showSearch
                  style={{ width: "280px" }}
                  value={position}
                  onSelect={this.onhandleSelectPosition}
                >
                  {this.state.positionArr.map((ele, i) => (
                    <Select.Option key={i} value={ele.id}>
                      {ele.title} - {ele.location}
                    </Select.Option>
                  ))}
                </Select>
              }
            </label>
          </span>

          <span className="current-section">
            <label>Rate</label>
            <Input
              style={{ width: "150px" }}
              value={rate}
              name="rate"
              onChange={this.handleChange}
              placeholder="Rate"
            />
          </span>
          <span className="current-section">
            <label>
              {country === "Canada"
                ? "SIN"
                : country === "US"
                ? "SSN"
                : "SSN/SIN"}
            </label>
            <Input
              style={{ width: "280px" }}
              value={ssn}
              name="ssn"
              onChange={this.handleChange}
              placeholder={
                country === "Canada"
                  ? "SIN"
                  : country === "US"
                  ? "SSN"
                  : "SSN/SIN"
              }
            />
            {ssn.length > 0 && ssn.length !== 9 && (
              <sapn style={{ fontSize: "9px", color: "red" }}>
                Please enter a valid SSN.
              </sapn>
            )}
          </span>

          <span className="current-section">
            {" "}
            <label> Date of Birth </label>
            <DatePicker
              allowClear={false}
              style={{ width: "150px" }}
              className="datepicker pickerCorrection"
              value={dob === "" ? "" : moment(dob)}
              format="MM-DD-YYYY"
              onChange={this.onDobChange}
            />
          </span>

          <span className="current-section">
            <label>Gender</label>
            <Radio.Group
              className="gender-sec"
              onChange={this.handleChange}
              name="gender"
              value={gender}
            >
              <Radio value="male">Male</Radio>
              <Radio value="female">Female</Radio>
            </Radio.Group>
          </span>

          <span className="current-section">
            <label>Marital Status : </label>
            {"  "}
            <Radio.Group
              className="gx-radio-group-link gx-radio-group-link-news"
              onChange={this.onMarraigeChange}
              value={maritalStatus}
            >
              <Radio value={"married"} className="gx-mb-1">
                Married
              </Radio>
              <Radio value={"unmarried"} className="gx-mb-1">
                Unmarried
              </Radio>
            </Radio.Group>
          </span>
        </div>

        <div className="submit-btn-right">
          <Button type="primary" onClick={this.onSubmit}>
            Submit
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.auth.accessData,
});

export default connect(mapStateToProps)(EditProfile);
