
import React from "react";
import Jobs from "./Jobs";
import Calendar from "./Calendar";
import Settings from "./Settings";
import Feedback from "./Feedback";
import Schedule from "./Schedule";
import Positions from "./Positions";
import Dashboard from "./Dashboard";
import GmailAuth from "./GmailAuth";
import MyProfile from "./MyProfile";
import UserActivity from "./UserActivity";
// import Protected from "../util/protected";
import Candidate from "./CandidateListView";
import Notifications from "./Notifications";
import { Route, Switch } from "react-router-dom";
// import RecruitmentDetail from "./Recruitment Detail";
import CandidateDetailView from "./CandidateDetailView";
import FormFields from "./Settings/CustomSettings/Forms";
import CreateEmailTemplate from "./Settings/CustomSettings/EmailTemplates/Create";
import { NotFound } from "../containers/App";
// import Reports from "./Reports/CandidateReport";
// import asyncComponent from "util/asyncComponent";
// import Todo from "./Todo";
// import Training from "./Training";
// import TrainingDetail from "./TrainingDetailView";
// import Mail from "./MailBox";
// import RecruitmentReport from "./Reports/RecruitmentReport";
// import Referral from "./Referral";

const App = ({ match, app }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}dashboard`}>
        <Dashboard />
      </Route>
      {/* <Route path={`${match.url}reports`}>
        <Reports />
      </Route> */}
      {/* <Route
        path={`${match.url}recruitmentReports`}
        children={
          <Protected isAllowed={app === "us"} match={match}>
            <RecruitmentReport />
          </Protected>
        }
      ></Route> */}
      <Route exact={true} path={`${match.url}candidate`}>
        <Candidate />
      </Route>
      <Route path={`${match.url}candidate/`}>
        <CandidateDetailView />
      </Route>
      <Route path={`${match.url}calendar`}>
        <Calendar />
      </Route>
      <Route exact={true} path={`${match.url}jobs`}>
        <Jobs />
      </Route>
      <Route exact={true} path={`${match.url}schedule`}>
        <Schedule />
      </Route>
      <Route path={`${match.url}positions`}>
        <Positions />
      </Route>
      <Route path={`${match.url}myprofile`}>
        <MyProfile />
      </Route>
      <Route path={`${match.url}notification`}>
        <Notifications />
      </Route>
      <Route path={`${match.url}activity`}>
        <UserActivity />
      </Route>
      <Route path={`${match.url}settings`}>
        <Settings app={app} />
      </Route>
      <Route path={`${match.url}feedback/:candidate_id/:type/:edit/:eval_id`}>
        <Feedback />
      </Route>
      <Route path={`${match.url}template`}>
        <CreateEmailTemplate />
      </Route>
      <Route path={`${match.url}form`}>
        <FormFields />
      </Route>
      <Route path={`${match.url}auth`}>
        <GmailAuth />
      </Route>
      <Route path="*">
        {/* Need to add error page */}
        <NotFound />
      </Route>
      {/* <Route path={`${match.url}todo`}>
        <Route
          exact={true}
          path={`${match.url}recruiter_detail`}
          children={
            <Protected isAllowed={app === "us"} match={match}>
              <RecruitmentDetail />
            </Protected>
          }
        ></Route>
        <Todo />
      </Route> */}
      {/* <Route path={`${match.url}mail`}>
        <Mail />
      </Route> */}
      {/* <Route
        exact={true}
        path={`${match.url}training`}
        children={
          <Protected isAllowed={app === "us"} match={match}>
            <Training />
          </Protected>
        }
      ></Route> */}
      {/* <Route
        path={`${match.url}training/`}
        children={
          <Protected isAllowed={app === "us"} match={match}>
            <TrainingDetail />
          </Protected>
        }
      ></Route> */}
      {/* <Route
        path={`${match.url}referral/`}
        children={
          <Protected isAllowed={app !== "us"} match={match}>
            <Referral />
          </Protected>
        }
      ></Route> */}
      {/* <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard'))}/> */}
      {/* <Route path={`${match.url}reports`} component={asyncComponent(() => import('./Reports'))}/> */}
      {/* <Route path={`${match.url}calendar`} component={asyncComponent(() => import('./Calendar'))}/> */}
      {/* <Route exact={true} path={`${match.url}candidate`} component={asyncComponent(() => import('./CandidateListView'))}/> */}
      {/* <Route path={`${match.url}candidate/`} component={asyncComponent(() => import('./CandidateDetailView'))}/> */}
      {/* <Route exact={true} path={`${match.url}jobs`} component={asyncComponent(() => import('./Jobs'))}/> */}
      {/* <Route exact={true} path={`${match.url}schedule`} component={asyncComponent(() => import('./Schedule'))}/> */}
      {/* <Route path={`${match.url}positions`} component={asyncComponent(() => import('./Positions'))}/> */}
      {/* <Route path={`${match.url}todo`} component={asyncComponent(() => import('./Todo'))}/> */}
      {/* <Route path={`${match.url}myprofile`} component={asyncComponent(() => import('./MyProfile'))}/> */}
      {/* <Route path={`${match.url}notification`} component={asyncComponent(() => import('./Notifications'))}/> */}
      {/* <Route path={`${match.url}activity`} component={asyncComponent(() => import('./UserActivity'))}/> */}
      {/* <Route path={`${match.url}settings`} component={asyncComponent(() => import('./Settings'))}/> */}
      {/* <Route exact={true} path={`${match.url}training`} component={asyncComponent(() => import('./Training'))}/> */}
      {/* <Route path={`${match.url}feedback/:candidate_id/:type/:edit/:eval_id`} component={asyncComponent(() => import('./Feedback'))}/> */}
      {/* <Route path={`${match.url}forgot-password`} component={asyncComponent(() => import('../containers/ForgotPassword'))}/> */}
      {/* <Route path={`${match.url}template`} component={asyncComponent(() => import('./Settings/CustomSettings/EmailTemplates/Create'))}/> */}
      {/* <Route path={`${match.url}mail`} component={asyncComponent(() => import('./MailBox'))}/> */}
      {/* <Route path={`${match.url}training/`} component={asyncComponent(() => import('./TrainingDetailView'))}/> */}
      {/* <Route path={`${match.url}form`} component={asyncComponent(() => import('./Settings/CustomSettings/Forms'))}/> */}
      {/* <Route path={`${match.url}auth`} component={asyncComponent(() => import('./GmailAuth'))}/> */}
    </Switch>
  </div>
);
//some text for test
export default App;
