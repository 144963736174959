import { message, Modal as Mod } from "antd";
import React, { useEffect, useState } from "react";
import BugReporter from "bug-reporter-react";
import { DASHBOARD_URL } from "../util/Api";
import { connect } from "react-redux";
import { APP_NAME } from "./constant";

const { confirm } = Mod;

const CustomModall = (props) => {
  const [flag, setFlag] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setFlag(true);
    }, 380);
  }, []);

  const getStatus = (mesg, messagetype) => {
    messagetype ? message.success(mesg) : message.error(mesg);
  };

  return (
    <div>
      {!props.onOk ? (
        <Mod
          className={props.className}
          title={props.title}
          open={props.visible}
          width={props.width}
          footer={
            <div>
              {props.footer}
              {flag && (
                <BugReporter
                  buttonStyle={{
                    position: "fixed",
                    bottom: 90,
                    right: 100,
                  }}
                  url={DASHBOARD_URL}
                  name={
                    props.currentAuth ? props.currentAuth.name : props.auth.name
                  }
                  email={
                    props.currentAuth
                      ? props.currentAuth.email
                      : props.auth.email
                  }
                  appName={"CloudeTech Beats"}
                  getStatus={getStatus}
                />
              )}
            </div>
          }
          zIndex={props.zIndex}
          onCancel={props.onCancel}
          onOk={props.onOk}
          wrapClassName={props.wrapClassName}
          style={props.style}
          okType={props.okType}
          okText={props.okText}
          okButtonProps={props.okButtonProps}
          modalRender={props.modalRender}
          maskStyle={props.maskStyle}
          maskClosable={props.maskClosable}
          mask={props.mask}
          keyboard={props.keyboard}
          getContainer={props.getContainer}
          forceRender={props.forceRender}
          focusTriggerAfterClose={props.focusTriggerAfterClose}
          destroyOnClose={props.destroyOnClose}
          confirmLoading={props.confirmLoading}
          closeIcon={props.closeIcon}
          closable={props.closable}
          centered={props.centered}
          cancelText={props.cancelText}
          cancelButtonProps={props.cancelButtonProps}
          bodyStyle={props.bodyStyle}
          afterClose={props.afterClose}
        >
          {props.children}
        </Mod>
      ) : (
        <Mod
          className={props.className}
          title={props.title}
          visible={props.visible}
          width={props.width}
          zIndex={props.zIndex}
          onCancel={props.onCancel}
          onOk={props.onOk}
          wrapClassName={props.wrapClassName}
          style={props.style}
          okType={props.okType}
          okText={props.okText}
          okButtonProps={props.okButtonProps}
          modalRender={props.modalRender}
          maskStyle={props.maskStyle}
          maskClosable={props.maskClosable}
          mask={props.mask}
          keyboard={props.keyboard}
          getContainer={props.getContainer}
          forceRender={props.forceRender}
          focusTriggerAfterClose={props.focusTriggerAfterClose}
          destroyOnClose={props.destroyOnClose}
          confirmLoading={props.confirmLoading}
          closeIcon={props.closeIcon}
          closable={props.closable}
          centered={props.centered}
          cancelText={props.cancelText}
          cancelButtonProps={props.cancelButtonProps}
          bodyStyle={props.bodyStyle}
          afterClose={props.afterClose}
        >
          {flag && (
            <BugReporter
              buttonStyle={{
                position: "fixed",
                bottom: 90,
                right: 100,
              }}
              url={DASHBOARD_URL}
              name={
                props.currentAuth ? props.currentAuth.name : props.auth.name
              }
              email={
                props.currentAuth ? props.currentAuth.email : props.auth.email
              }
              appName={"CloudeTech Beats"}
              getStatus={APP_NAME}
            />
          )}
          {props.children}
        </Mod>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth.authUser,
  currentAuth: state.auth.newUser,
});

const CustomModal = connect(mapStateToProps)(CustomModall);

export { CustomModal, confirm };
