import React, { useEffect, useState } from "react";
import { message, Button, Pagination, Spin } from "antd";
import axiosJSON from "../../../../util/SetHeaderAPI";
import UserList from "./list";
// import AppModuleHeader from "../../../../components/AppModuleHeader";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
// import ChangeTeam from "../../../../modals/Settings/UserDirectory/changeTeam";
import Handover from "../../../../modals/Settings/UserDirectory/handover";
import { CustomModal as Modal } from "../../../../util/CustomModal";
import SingleUserCreation from "../../../../modals/Settings/UserDirectory/singleUserCreation";
import BulkUserCreation from "../../../../modals/Settings/UserDirectory/bulkUserCreation";
import { BsArrowLeftCircle } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import AppModuleHeader from "../../../../components/AppModuleHeader";

// const { confirm } = Modal;

const UserDirectory = (props) => {
  const { authUser } = useSelector(({ auth }) => auth);
  const [allUsers, setUsers] = useState([]);
  const [query, setQuery] = useState("");
  const [flag, setFlag] = useState("");
  const [user, setUser] = useState("");
  const [showUserCreation, setUserCreation] = useState(false);
  const [showBulkUser, setBulkUser] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSize, setCurrentSize] = useState(10);
  const [total, setTotal] = useState(null);
  const [loader, setLoader] = useState(false);
  const [log1RoleList, setLog1RoleList] = useState([]);

  useEffect(() => {
    getDirectory(query, currentPage, currentSize);
    getLog1RoleList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectOptions = (e, user) => {
    setUser(user);
    switch (e.key) {
      case "0":
        setFlag("edit_user");
        break;
      case "1":
        if (user.team && user.team.name) {
          setFlag("change_access");
        } else {
          message.error("Please Update Team");
        }
        //  activateConfirm(user);
        break;
      // case "2":
      //   setFlag(!user.is_active ? "activate" : null);
      //   // activateConfirm(user);
      //   break;
      // case "3":
      //   setFlag("handover");
      //   break;
      case "4":
        if (user.team && user.team.name) {
          setFlag("change_role");
        } else {
          message.error("Please Update Team");
        }
        break;
      default:
        setFlag("");
        break;
    }
  };

  const singleUserCreation = () => {
    setUserCreation(true);
  };

  const bulkUserCreation = () => {
    setBulkUser(true);
  };

  const getDirectory = (query, page, size) => {
    setLoader(true);
    axiosJSON
      .get(`/api/user/user_list/?query=${query}&page=${page}&page_size=${size}`)
      .then(({ data, status }) => {
        setUsers([...data.data]);
        setTotal([data.total]);
        setLoader(false);
      })
      .catch((e) => {
        message.error(e);
        setLoader(false);
      });
  };

  const getLog1RoleList = () => {
    axiosJSON
      .get("/api/user/log1_role/")
      .then((data) => {
        setLog1RoleList(data.data.data);
      })
      .catch(function (error) {
        message.error(error?.message);
      });
  };

  const handleQuery = (e) => {
    setQuery(e.target.value);
    getDirectory(e.target.value, 1, currentSize);
  };

  const handleCancel = () => {
    setFlag("");
    setUserCreation(false);
    getDirectory(query, 1, currentSize);
  };

  const handleBulkCancel = () => {
    setFlag("");
    setBulkUser(false);
    getDirectory(query, 1, currentSize);
  };

  const handleEditClose = () => {
    getDirectory(query, 1, currentSize);
    setFlag("");
  };

  const onPageChange = (page, size) => {
    setCurrentPage(page);
    setCurrentSize(size);
    getDirectory(query, page, size);
  };

  // const activationUser = (user) => {
  //   const body = {
  //     account: !user.is_active ? "activate" : null,
  //   };
  //   axiosJSON
  //     .put("api/user/" + user.id + "/account/", body)
  //     .then(({ data, status }) => {
  //       if (status === 202) {
  //         message.success(data.message);
  //         handleEditClose();
  //       }
  //     });
  // };

  // const activateConfirm = (user) => {
  //   confirm({
  //     title: "Are you sure?",
  //     icon: <ExclamationCircleOutlined />,
  //     content: !user.is_active ? "This action will active user." : null,
  //     onOk() {
  //       activationUser(user);
  //     },
  //     onCancel() {
  //       handleEditClose();
  //     },
  //   });
  // };

  return (
    <>
      <div className="gx-module-box-header">
        <div className="">
          <div className="row m-0 headDir space-bet mb-3 pb-4 mt-2">
            <div className="row m-0 back2">
              <BsArrowLeftCircle
                className="back-btn mr-4"
                onClick={() => {
                  props.history.push("/settings");
                  props.changeSawDrawer(true);
                }}
              />
              <div className="assetsdr">
                <FiUsers className="mr-2" />
                <div className="assert-head">User Directory</div>
              </div>
            </div>
            <div className="">
              {authUser &&
              (authUser.role === "superadmin" ||
                authUser.role === "admin" ||
                authUser.role === "hr") ? (
                <div className="button-wrap">
                  <Button
                    type="primary"
                    onClick={() => singleUserCreation(showUserCreation)}
                    style={{ width: "fit-content" }}
                  >
                    Add Single User
                  </Button>
                  <Modal
                    title="Add Single User"
                    className="single_user"
                    visible={showUserCreation}
                    footer={null}
                    onCancel={handleCancel}
                    destroyOnClose={true}
                  >
                    <SingleUserCreation
                      edit={false}
                      role={false}
                      deactive={false}
                      destroyOnClose={true}
                      showUserCreation={showUserCreation}
                      closeModal={handleCancel}
                    />
                  </Modal>
                  <Button
                    type="primary"
                    onClick={() => bulkUserCreation(showBulkUser)}
                    style={{ width: "fit-content" }}
                  >
                    Add Bulk Users
                  </Button>

                  <Modal
                    title="Create Bulk Users"
                    className="bulk_user"
                    visible={showBulkUser}
                    footer={null}
                    onCancel={handleBulkCancel}
                    destroyOnClose={true}
                  >
                    <BulkUserCreation
                      closeModal={handleBulkCancel}
                      destroyOnClose={true}
                    />
                  </Modal>
                  <AppModuleHeader
                    placeholder="Search User"
                    onChange={handleQuery}
                    value={query}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <Spin spinning={loader}>
          <div className="gx-module-box-content">
            <UserList
              userList={allUsers}
              authUser={authUser}
              selectOptions={selectOptions}
              log1RoleList={log1RoleList}
            />
            <div className="rightPagination userDirectory-pagination px-2">
              <Pagination
                current={currentPage}
                onChange={onPageChange}
                showSizeChanger
                onShowSizeChange={onPageChange}
                pageSize={currentSize}
                total={total}
              />
            </div>
          </div>
        </Spin>
        {flag === "edit_user" && (
          <Modal
            className="single_user"
            title="Edit Single User"
            visible={flag === "edit_user"}
            footer={null}
            onCancel={handleCancel}
          >
            <SingleUserCreation
              edit={true}
              user={user}
              activate={false}
              role={false}
              deactive={false}
              destroyOnClose={true}
              showUserCreation={showUserCreation}
              closeModal={handleCancel}
            />
          </Modal>
        )}{" "}
        {flag === "handover" && (
          <Modal
            title="Handover"
            visible={flag === "handover"}
            footer={null}
            onCancel={handleCancel}
          >
            <Handover user={user} closeModal={handleEditClose} />
          </Modal>
        )}{" "}
        {flag === "change_role" && (
          <Modal
            className="single_user"
            title="Change Roles"
            visible={flag === "change_role"}
            footer={null}
            onCancel={handleCancel}
          >
            <SingleUserCreation
              edit={false}
              deactive={false}
              activate={false}
              role={true}
              user={user}
              destroyOnClose={true}
              showUserCreation={showUserCreation}
              closeModal={handleCancel}
            />
          </Modal>
        )}
        {flag === "change_access" && (
          <Modal
            className="single_user"
            title="Activate / Deactivate User"
            visible={flag === "change_access"}
            footer={null}
            onCancel={handleCancel}
          >
            <SingleUserCreation
              // deactive={true}
              // activate={false}
              changeAccess={true}
              edit={false}
              role={false}
              user={user}
              destroyOnClose={true}
              showUserCreation={showUserCreation}
              closeModal={handleCancel}
            />
          </Modal>
        )}
        {/* {flag === "activate" && (
        <Modal
          className="single_user"
          title="Activate / Deactivate User"
          visible={flag === "activate"}
          footer={null}
          onCancel={handleCancel}
        >
          <SingleUserCreation
            activate={true}
            deactive={false}
            edit={false}
            role={false}
            user={user}
            destroyOnClose={true}
            showUserCreation={showUserCreation}
            closeModal={handleCancel}
          />
        </Modal>
      )} */}
      </div>
    </>
  );
};

export default withRouter(UserDirectory);
