import React, { useEffect, useState } from "react";
import { Menu, message } from "antd";
import { Link, useLocation } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

import { useDispatch, useSelector } from "react-redux";
import {
  setFilterObj,
  setHeaderData,
  setPermissionData,
  setSortBy,
  setAll,
  onSearch,
} from "../../appRedux/actions";
import axiosJSON from "../../util/SetHeaderAPI";
// import { BiBarChartSquare } from "react-icons/bi";

const SidebarContent = () => {
  const dispatch = useDispatch();
  const [app, setApp] = useState("us");
  // const { authUser, newUser } = useSelector(({ auth }) => auth);
  let { navStyle, themeType } = useSelector(({ settings }) => settings);

  useEffect(() => {
    getAppName();
  }, []);

  const getAppName = () => {
    axiosJSON
      .get("/api/util/app/")
      .then(({ data, status }) => {
        setApp(data.data);
      })
      .catch((error) => message.error(error));
  };

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  const location = useLocation();

  const selectedKeys = location.pathname.split("/")[1];
  const defaultOpenKeys = selectedKeys;
  // const onTrainingClick = () => {
  //   localStorage.removeItem("trainingObj");
  // };

  // const checkRole = (user) => {
  //   let check = false;
  //   if (
  //     user.role === "admin" ||
  //     user.role === "superadmin" ||
  //     user.dept === "Recruitment"
  //   ) {
  //     check = true;
  //   }
  //   return check;
  // };

  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content sidebar-new">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          {console.log(app)}
          <UserProfile />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="vertical"
          >
            <Menu.Item key="dashboard">
              <Link
                to="/dashboard"
                onClick={() => {
                  localStorage.removeItem("tab");
                }}
              >
                <i className="icon icon-dasbhoard" />
                <IntlMessages id="sidebar.dashboard" />
              </Link>
            </Menu.Item>

            {/* {app === "us" ? (
              <Menu.SubMenu
                title="Reports"
                icon={<BiBarChartSquare />}
                key="submenuReports"
              >
                <Menu.Item key="reports">
                  <Link to="/reports">
                    <i className="icon icon-user" />
                    <IntlMessages id="sidebar.candidateReports" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="recruitmentReports">
                  <Link to="/recruitmentReports">
                    <i className="icon icon-stats" />
                    <IntlMessages id="sidebar.recruitmentReports" />
                  </Link>
                </Menu.Item>
              </Menu.SubMenu>
            ) : (
              <Menu.Item key="reports">
                <Link to="/reports">
                  <i className="icon icon-stats" />
                  <IntlMessages id="sidebar.reports" />
                </Link>
              </Menu.Item>
            )} */}

            <Menu.Item key="candidate">
              <Link
                to="/candidate"
                onClick={() => {
                  dispatch(setHeaderData(null));
                  dispatch(setPermissionData(null));
                  dispatch(setFilterObj({}));
                  dispatch(setAll("my"));
                  dispatch(setSortBy(""));
                  dispatch(onSearch(""));
                  localStorage.removeItem("tab");
                }}
              >
                <i className="icon icon-contacts" />
                <IntlMessages id="sidebar.candidate" />
              </Link>
            </Menu.Item>
            {/* 
            {app === "us" &&
              (newUser ? checkRole(newUser) : checkRole(authUser)) && (
                <Menu.Item key="recruiter_detail">
                  <Link to="/recruiter_detail">
                    <i className="icon icon-team" />
                    <IntlMessages id="sidebar.recruitement" />
                  </Link>
                </Menu.Item>
              )} */}

            <Menu.Item key="calendar">
              <Link
                to="/calendar"
                onClick={() => {
                  localStorage.removeItem("tab");
                }}
              >
                <i className="icon icon-schedule" />
                <IntlMessages id="sidebar.calendar" />
              </Link>
            </Menu.Item>

            {/* {app === "us" && (
              <Menu.Item key="training">
                <Link
                  to="/training"
                  onClick={() => {
                    onTrainingClick();
                    dispatch(setFilterObj({}));
                    dispatch(onSearch(""));
                    localStorage.removeItem("tab");
                  }}
                >
                  <i className="icon icon-graduation" />
                  <IntlMessages id="sidebar.training" />
                </Link>
              </Menu.Item>
            )} */}

            <Menu.Item key="positions">
              <Link
                to="/positions"
                onClick={() => {
                  localStorage.removeItem("tab");
                }}
              >
                <i className="icon icon-plain-list-divider" />
                <IntlMessages id="sidebar.positions" />
              </Link>
            </Menu.Item>

            <Menu.Item key="schedule">
              <Link
                to="/schedule"
                onClick={() => {
                  localStorage.removeItem("tab");
                }}
              >
                <i className="icon icon-calendar" />
                <IntlMessages id="sidebar.schedule" />
              </Link>
            </Menu.Item>
            {/* 
            <Menu.Item key="mail">
              <Link
                to="/mail#inbox"
                onClick={() => {
                  localStorage.removeItem("tab");
                }}
              >
                <i className="icon icon-email" />
                <IntlMessages id="sidebar.mail" />
              </Link>
            </Menu.Item>

            <Menu.Item key="todo">
              <Link
                to="/todo"
                onClick={() => {
                  localStorage.removeItem("tab");
                }}
              >
                <i className="icon icon-tasks" />
                <IntlMessages id="sidebar.todo" />
              </Link>
            </Menu.Item> */}

            <Menu.Item key="settings">
              <Link
                to="/settings"
                onClick={() => {
                  localStorage.removeItem("tab");
                }}
              >
                <i className="icon icon-setting" />
                <IntlMessages id="sidebar.settings" />
              </Link>
            </Menu.Item>
            {/* 
            {app !== "us" && (
              <Menu.Item key="referral">
                <Link
                  to="/referral"
                  // onClick={() => {
                  //   localStorage.removeItem("tab");
                  // }}
                >
                  <i className="icon icon-refer" />
                  <IntlMessages id="sidebar.referral" />
                </Link>
              </Menu.Item>
            )} */}
          </Menu>
        </CustomScrollbars>

        <div
          className={`gx-sidebar-notifications}`}
          style={{
            height: "30vh",
            width: "100%",
          }}
        >
          <h1 style={{ color: "white", marginLeft:"40px" }}>CloudTech</h1>
        </div>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
