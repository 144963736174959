import React from "react";
import {Button, Input, message} from "antd";
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import axiosJSON from "../../util/SetHeaderAPI";

class EditPhone extends React.Component {
  state = {
    listKeys: [],
    phone_arr: []
  };
  Mounted=false

  componentWillUnmount() {
    this.Mounted = false
  }

  componentDidMount() {
    this.Mounted = true
    let list_arr = this.state.listKeys;
    let phone_arr = this.state.phone_arr;
    if (this.props.phone !== null && this.props.phone.length !== 0) {
      phone_arr = this.props.phone;
      this.props.phone.map((ele, i) => list_arr.push(i))
    }
    this.setState({
      listKeys: list_arr,
      phone_arr: phone_arr
    })
  }

  remove = k => {
    const {listKeys, phone_arr} = this.state;

    let number = phone_arr[k]

    this.setState({
      phone_arr: phone_arr.filter(key => number !== key),
      listKeys: listKeys.filter(key => key !== k),
    });

  };

  add = () => {
    const {listKeys, phone_arr} = this.state;
    phone_arr.push("")
    listKeys.push(listKeys.length)

    this.setState({
      phone_arr: phone_arr,
      listKeys: listKeys,
    });
  };
  onSubmit = () => {
    const body = {
      "phone_numbers": this.state.phone_arr
    }
    axiosJSON.put('api/candidate/' + this.props.id + '/', body)
      .then(({data, status}) => {
        if (status === 202) {
          message.success(data.message)
          this.props.closeModal()

        }
      }).catch(function (error) {
      message.error(error.message)
    })
  }
  handleChange = (e, index) => {
    let phone_arr = this.state.phone_arr;
    phone_arr[index] = e.target.value

    this.setState({
      phone_arr: phone_arr,
    })
  }


  render() {
    const {listKeys, phone_arr} = this.state;
    return (
      <div>
        {listKeys.map((key, index) => {
          return (

            <div key={key} className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
              <Input value={phone_arr[index]} onChange={(e) => this.handleChange(e, index)}
                     placeholder="Phone Number"/>
              <DeleteOutlined
                className="dynamic-delete-button delete-icon-phone"
                onClick={() => this.remove(key)}
              />
            </div>

          );
        })}
        <Button type="dashed" onClick={this.add}>
          <PlusOutlined/>
          Add more phone numbers
        </Button>
        <Button type="primary" htmlType="submit" onClick={this.onSubmit}>
          Submit
        </Button>
      </div>

    );
  }
}

export default EditPhone;
