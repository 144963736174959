import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, Select, Radio } from "antd";
import axiosJSON from "../../util/SetHeaderAPI";
import { useSelector } from "react-redux";

const FormItem = Form.Item;

const AddCandidate = (props) => {
  const [fields, setFields] = useState([
    {
      name: ["name"],
      value: "",
    },
    {
      name: ["email"],
      value: "",
    },
  ]);
  const { timezone } = useSelector(({ common }) => common);
  const [phone, setPhone] = useState("");
  const [positionList, setPositionList] = useState([]);
  const [position_id, setPositionArr] = useState("");
  const [maritalStatus, setmarital] = useState(null);

  useEffect(() => {
    getPositionList();
  }, []);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const getPositionList = () => {
    axiosJSON
      .get("api/position/") //need to change here
      .then(({ data, status }) => {
        setPositionList(data.data);
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };
  const onPhoneChange = (value) => {
    let pattern = /[^0-9]/g;
    let correctNum = value.replace(pattern, "");
    setPhone(correctNum);
  };

  const onFinish = (values) => {
    const body = {
      name: values.name,
      email: values.email,
      phone_numbers: phone === "" ? [] : [phone],
      job_position: position_id,
      marital_status: maritalStatus,
    };
    if (phone.length <= 10) {
      axiosJSON
        .post("api/candidate/", body)
        .then(({ data, status }) => {
          if (status === 201) {
            message.success("Candidate Added!");
            props.closeModal();
          }
        })
        .catch(function (error) {
          message.error(error.message);
        });
    } else {
      message.error("Phone is not correct");
    }
  };

  const onMarraigeChange = (event) => {
    setmarital(event.target.value);
  };

  const onPositionSelect = (arr) => {
    setPositionArr(arr);
  };
  return (
    <div className="gx-login-container">
      <div className="gx-login-content">
        <Form
          fields={fields}
          name="basic"
          // onValuesChange={(changedValues, allValues) => {
          //   if (changedValues.hasOwnProperty("phone")) {
          //     let pattern = /[^0-9]/g
          //     let correctNum = changedValues.phone.replace(pattern, '')
          //     let f = [...fields, {name: "phone", value: correctNum}]
          //     setFields(f)
          //   }
          // }}
          onFieldsChange={(newFields) => {
            setFields(newFields);
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0"
        >
          <FormItem
            rules={[
              { required: true, message: "Please input Candidate's Name!" },
            ]}
            name="name"
          >
            <Input
              className="gx-input-lineheight"
              type="text"
              placeholder="Candidate's Name"
            />
          </FormItem>

          <FormItem
            rules={[
              { required: true, message: "Please input Candidate's Email!" },
              { type: "email", message: "Invalid Email" },
            ]}
            name="email"
          >
            <Input
              className="gx-input-lineheight"
              type="email"
              placeholder="Candidate's Email"
            />
          </FormItem>

          <FormItem>
            <Input
              value={phone}
              onChange={(e) => {
                let pattern = /[^0-9]/g; //+1492-322-3232
                let correctNum = e.target.value.replace(pattern, "");
                if (timezone === "EST") {
                  if (correctNum.startsWith("1")) {
                    correctNum = correctNum.slice(1, correctNum.length);
                  }
                } else {
                  if (correctNum.startsWith("91")) {
                    correctNum = correctNum.slice(2, correctNum.length);
                  } else {
                    if (correctNum.startsWith("0")) {
                      correctNum = correctNum.slice(1, correctNum.length);
                    }
                  }
                }
                onPhoneChange(correctNum);
              }}
              className="gx-input-lineheight"
              type="text"
              placeholder="Candidate's Phone number"
            />
          </FormItem>

          <FormItem name="position">
            <Select
              style={{ width: "100%" }}
              value={position_id}
              onChange={onPositionSelect}
              placeholder="Job Position"
            >
              {positionList.map((position, i) => (
                <Select.Option value={position.id} key={position.id}>
                  {position.title + ":" + position.location}
                </Select.Option>
              ))}
            </Select>
          </FormItem>

          <FormItem name="married">
            Marital Status: {"  "}
            <Radio.Group
              className="gx-radio-group-link gx-radio-group-link-news"
              onChange={onMarraigeChange}
            >
              <Radio value={"married"} className="gx-mb-1">
                Married
              </Radio>
              <Radio value={"unmarried"} className="gx-mb-1">
                Unmarried
              </Radio>
            </Radio.Group>
          </FormItem>

          <FormItem>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </FormItem>
        </Form>
      </div>
    </div>
  );
};

export default AddCandidate;
