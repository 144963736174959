import React from "react";
import { Tag } from "antd";
import moment from "moment";

export default class MeetingDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      candidate_id: this.props.data.candidate.id,
      interview_type: [
        {
          name: "CFR",
          value: "cfr",
        },
        {
          name: "Coding",
          value: "coding",
        },
        {
          name: "Initial Screening",
          value: "initial_screening",
        },
        {
          name: "Post Training Screening",
          value: "post_training",
        },
      ],
    };
  }

  componentWillUnmount() {
    this.Mounted = false;
  }

  componentDidMount() {
    this.Mounted = true;
  }

  render() {
    const { data } = this.state;
    let status_style = {};
    this.state.data.status.id === 6
      ? (status_style = {
          background: "#ff3333",
          color: "black",
        })
      : (status_style = { background: "#b3ff66" });
    return (
      <div className="gx-login-container">
        <div className="gx-login-content education-form">
          <div className="form-dropdown">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                gap:"10px"
              }}
            >
              <h3 >
                {data.candidate.name} :: {data.position.display_name}
              </h3>

              <Tag className="cacelled-tag" style={status_style}>
                {data.status.display_name}
              </Tag>
            </div>
          </div>

          <ul>
            <li>
              <span style={{marginRight:"10px"}}>Type:</span>
              {this.state.interview_type.map((type, index) =>
                type.value === data.type ? type.name : null
              )}
            </li>
            {/*<li> <span>Candidate : </span> {data.candidate.name} </li>*/}
            {/*<li> <span>Position: </span> {data.position.display_name} </li>*/}
            <li>
              <span style={{marginRight:"10px"}}>Interviewers : </span>{" "}
              {data.interviewers.map((interviewer) => (
                <Tag>{interviewer.name}</Tag>
              ))}
            </li>
            <li>
              <span style={{marginRight:"10px"}}>Guests : </span>{" "}
              {data.guest.length > 0
                ? data.guest.map((guest, index) => (
                    <Tag key={index}>{guest.name}</Tag>
                  ))
                : "No guest"}{" "}
            </li>

            <li>
              <span style={{marginRight:"10px"}}>Schedule : </span>{" "}
              {moment(data.start).format("YYYY-MM-DD hh:mm A") +
                " to " +
                moment(data.end).format("YYYY-MM-DD hh:mm A")}
            </li>
            <li>
              <span style={{marginRight:"10px"}}>Description: </span>
              <p
                dangerouslySetInnerHTML={{
                  __html: data.description,
                }}
              />
            </li>
            <li>
              <span style={{marginRight:"10px"}}>Created By : </span> {data.created_by} on{" "}
              {data.modified
                ? moment(data.modified).format("YYYY-MM-DD hh:mm A")
                : moment(data.created).format("YYYY-MM-DD hh:mm A")}
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
