import React, { useState, useEffect } from "react";
import { Button, Card, Tag, message, Popconfirm } from "antd";
import AddEducation from "../../../../../modals/Candidate/addEducation";
import EditSkill from "../../../../../modals/Candidate/editSkill";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import axiosJSON from "../../../../../util/SetHeaderAPI";
import { CustomModal as Modal } from "../../../../../util/CustomModal";

const Education = ({ id }) => {
  const [showMore, setShow] = useState(false);
  const [showSkill, setSkill] = useState(false);
  const [skills_list, setskillsList] = useState([]);
  const [showEdit, setEdited] = useState(false);
  const [eduList, setEduList] = useState([]);
  const [education, setEducation] = useState({});

  const handleShow = (flag) => {
    setShow(!flag);
  };

  const handleSkill = (flag) => {
    setSkill(!flag);
  };

  const handleEditShow = (flag, listitem) => {
    setEdited(!flag);
    setEducation(listitem);
  };

  const handleclose = () => {
    setShow(false);
    setEducation({});
    setEdited(false);
    setSkill(false);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const fetchData = () => {
    axiosJSON
      .get("api/candidate/" + id + "/exp_edu/")
      .then((res) => {
        const eduList = res.data.data.education;
        const skill_list = res.data.data.skills;
        setEduList(eduList);
        setskillsList(skill_list);
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  const deleteConfirm = (edu_id) => {
    axiosJSON
      .delete("api/candidate/" + id + "/education/" + edu_id + "/")
      .then((res) => {
        message.success("Education Deleted!");
        fetchData();
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      fetchData();
    }
    return function cleanup() {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div>
        <h1>EDUCATION :</h1>
        {eduList.map((listitem, index) => (
          <Card key={index}>
            <div className="education-section">
              <ul>
                <li>
                  <i className="icon icon-auth-screen" />
                </li>
                <li>
                  <span className="edu-top-headline">{listitem.org_name}</span>
                  <br></br>
                  <span>{listitem.major}</span>
                  <br></br>
                  <span>{listitem.edu_type}</span>
                  <br></br>
                  <span>{listitem.start_date}</span>
                  <br></br>
                  <span>{listitem.city}</span>
                  <br></br>
                  <span>{listitem.remark}</span>
                  <br></br>
                </li>
                <li>
                  <div className="icons">
                    <EditOutlined
                      onClick={() => handleEditShow(showEdit, listitem)}
                    />
                    <Popconfirm
                      title="Are you sure delete this education?"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => deleteConfirm(listitem.id)}
                    >
                      <DeleteOutlined />
                    </Popconfirm>
                  </div>
                </li>
              </ul>
            </div>
          </Card>
        ))}
        <Modal
          title="Edit Education"
          visible={showEdit}
          footer={null}
          onCancel={() => setEdited(false)}
        >
          <AddEducation
            id={id}
            closeModal={handleclose}
            edit={true}
            data={education}
          />
        </Modal>
        <Button onClick={() => handleShow(showMore)}>Add Education</Button>
        <Modal
          title="Add Education"
          visible={showMore}
          footer={null}
          onCancel={() => setShow(false)}
        >
          <AddEducation
            id={id}
            closeModal={handleclose}
            edit={false}
            data={{}}
          />
        </Modal>
      </div>
      <div>
        <h1>SKILLS :</h1>
        {skills_list !== null
          ? skills_list.map((skills, index) => <Tag key={index}>{skills}</Tag>)
          : null}
        <Button onClick={() => handleSkill(showSkill)}>Add Skills</Button>
        <Modal
          title="Add Skills"
          className="popupsize-new"
          visible={showSkill}
          footer={null}
          onCancel={() => setSkill(false)}
        >
          <EditSkill id={id} closeModal={handleclose} data={skills_list} />
        </Modal>
      </div>
    </div>
  );
};

export default Education;
