import React, { useState, useMemo, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Select,
  Table,
  Dropdown,
  Menu,
  message,
  Pagination,
  Spin,
  Popconfirm,
} from "antd";
import { CustomModal as Modal } from "../../../../util/CustomModal";
// import Checkbox from "antd/lib/checkbox/Checkbox";
import { BsArrowLeftCircle } from "react-icons/bs";
import { BiDevices } from "react-icons/bi";
import axiosJSON from "../../../../util/Api";
import ActionModal from "../../../../modals/Settings/AssestDirectory/ActionModal";

const AssetsDirectory = (props) => {
  const [total, setTotal] = useState([]);
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [employee, setEmployee] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSize, setCurrentSize] = useState(10);
  const [filterObject, setFilterObject] = useState({});
  const [brandOptions, setBrandOptions] = useState([]);
  const [actionModel, setActionModel] = useState(false);
  const [cardsChecked, setCardsChecked] = useState(false);
  const [deviceTypeOptions, setDeviceTypeOptions] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  // const [isListView, setIsListView] = useState(true);
  // const [checkedItems, setCheckedItems] = useState([]);
  // const [detailViewData, setDetailViewData] = useState([]);

  useEffect(() => {
    // isListView ? getListData(currentPage, currentSize) : getDetailViewData();
    getListData(1, 10, null);
    setCurrentPage(1);
    setCurrentSize(10);
    // eslint-disable-next-line
  }, [filterObject]);

  useEffect(() => {
    getAllUsers();
    getListData(currentPage, currentSize, null);
    getAllBrands();
    getAllDeviceTypes();
    // eslint-disable-next-line
  }, []);

  const getAllDeviceTypes = () => {
    setLoading(true);
    axiosJSON
      .get("/api/choice/?type=device_type")
      .then(({ data }) => {
        setDeviceTypeOptions(
          data.data.map((data) => {
            return {
              id: data.id,
              name: data.display_name,
            };
          })
        );
        setLoading(false);
      })
      .catch((error) => {
        message.error("Something went wrong, please reach to support");
        setLoading(false);
      });
  };

  const filterApiDataConverter = () => {
    let filters = {};
    if (filterObject.deviceType) {
      filters = {
        ...filters,
        device_type: [filterObject.deviceType],
      };
    }
    if (filterObject.brand && filterObject.brand.length > 0) {
      console.log(filterObject.brand);
      filters = {
        ...filters,
        brand: filterObject.brand,
      };
    }
    if (filterObject.status) {
      filters = {
        ...filters,
        in_use: filterObject.status === "in_use" ? true : false,
      };
    }
    if (filterObject.condition) {
      filters = {
        ...filters,
        working: filterObject.condition === "working" ? true : false,
      };
    }
    return filters;
  };

  // const getDetailViewData = (userId) => {
  //   let filters = filterApiDataConverter();
  //   if (userId || employee !== null) {
  //     setLoading(true);
  //     axiosJSON
  //       .get(
  //         `/api/user_assets/${
  //           userId !== undefined ? userId : employee ? employee : ""
  //         }/?filter_json=${JSON.stringify(filters)}`
  //       )
  //       .then(({ data }) => {
  //         const details = data.data.map((data) => {
  //           const obj = {
  //             id: data.id,
  //             model: data.model,
  //             is_working: data.is_working,
  //             in_use: data.in_use,
  //             brand: data.device_brand.display_name,
  //             deviceType: data.device_type.display_name,
  //           };
  //           return obj;
  //         });
  //         setDetailViewData(details);
  //         setLoading(false);
  //       })
  //       .catch((error) => {
  //         message.error("Something went wrong, please reach to support");
  //         setLoading(false);
  //       });
  //   }
  // };

  const getAllBrands = () => {
    setLoading(true);
    axiosJSON
      .get("/api/choice/?type=device_brand")
      .then(({ data }) => {
        let obj = {};
        let brands = data.data.map((data) => {
          obj = {
            name: data.display_name,
            id: data.id,
          };
          return obj;
        });
        setBrandOptions(brands);
      })
      .catch((error) => {
        message(error);
        setLoading(false);
      });
  };

  const getListData = (page, pageSize, userId) => {
    let filters = filterApiDataConverter();
    setLoading(true);
    let userID = userId;
    if (userId === undefined) {
      userID = "";
    } else {
      if (userId === null) {
        userID = employee ? employee : "";
      }
    }
    axiosJSON
      .get(
        `/api/user_assets/?filter_json=` +
          JSON.stringify(filters) +
          `&` +
          `emp_id=` +
          `${userID ? userID : ""}&page=${page}&page_size=${pageSize}`
      )
      .then(({ data }) => {
        setDataSource(
          data.data.map((data, index) => {
            return {
              id: data.id,
              index: index + 1,
              model_no: data.model,
              type: data.device_type.display_name,
              brand: data.device_brand.display_name,
              status: data.in_use ? "In use" : "Not in use",
              condition: data.is_working ? "Working" : "Not Working",
              employee_name: data.owner ? data.owner.display_name : "",
            };
          })
        );
        setTotal(data.count);
        setLoading(false);
      })
      .catch((error) => {
        message.error("Something went wrong, please reach to support");
        setLoading(false);
      });
  };

  const columns = [
    {
      header: "id",
      accessor: "id",
    },
    {
      title: "S. No",
      dataIndex: "index",
      key: "index",
    },
    {
      title: "Device Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Device Model No.",
      dataIndex: "model_no",
      key: "model_no",
    },
    {
      title: "Device Brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Condition",
      dataIndex: "condition",
      key: "condition",
    },
    {
      title: "Employee Name",
      dataIndex: "employee_name",
      key: "employee_name",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      render: (text, record) => (
        <Dropdown.Button
          overlay={menu(record.id, record.employee_name !== "")}
          trigger={["click"]}
        />
      ),
    },
  ];

  const onOptionMenuItemSelect = (event, id) => {
    if (event.key === "moveToEmployee") {
      const newSelectedItem = [...checkedItems];
      newSelectedItem.push(id);
      setCheckedItems(newSelectedItem);
      setActionModel(true);
    }
  };

  // const onSelectingCheckbox = (event, data) => {
  //   let newCheckedItems = [...checkedItems];
  //   if (event.target.checked) {
  //     newCheckedItems.push(data.id);
  //   } else {
  //     newCheckedItems = newCheckedItems.filter((id) => id !== data.id);
  //   }
  //   setCheckedItems(newCheckedItems);
  //   if (newCheckedItems.length > 0) {
  //     setCardsChecked(true);
  //   } else {
  //     setCardsChecked(false);
  //   }
  // };

  const onPageChange = (page, size) => {
    setCurrentPage(page);
    setCurrentSize(size);
    getListData(page, size, null);
  };

  const listView = useMemo(() => {
    return (
      <div className="row backgroud-custom">
        <Table
          columns={columns}
          loading={loading}
          className="assetinfo"
          dataSource={dataSource}
          style={{ width: "100%" }}
          initialState={{ hiddenColumns: ["id"] }}
          pagination={false}
        />
        {total > currentSize ? (
          <div
            className="rightPagination userDirectory-pagination px-2"
            style={{ width: "100%" }}
          >
            <Pagination
              total={total}
              showSizeChanger
              current={currentPage}
              pageSize={currentSize}
              onChange={onPageChange}
              onShowSizeChange={onPageChange}
            />
          </div>
        ) : null}
      </div>
    );
    // eslint-disable-next-line
  }, [dataSource, columns, actionModel, filterObject]);

  const returnToOfficeAPI = (id = null) => {
    setLoading(true);
    // const asset_ids = id !== null ? [id] : [...checkedItems];
    if (id !== null) {
      const asset_ids = [id];
      axiosJSON
        .put("/api/user_assets/return/", { asset_ids: asset_ids })
        .then((resp) => {
          message.success("Updated data successfully");
          // setDetailViewData([]);
          setCardsChecked(false);
          // setCheckedItems([]);
          getListData(currentPage, currentSize, null);

          // if (isListView) {
          //   getListData(currentPage, currentSize);
          // } else {
          //   getDetailViewData();
          // }
        })
        .catch((error) => {
          message.error("Something went wrong");
          setLoading(false);
        });
    }
  };

  const menu = (id, isReturnedAllready) => (
    <Menu onClick={(event) => onOptionMenuItemSelect(event, id)}>
      <Menu.Item key="moveToEmployee">
        <span>Move to Employee</span>
      </Menu.Item>
      {isReturnedAllready ? (
        <Menu.Item key="returnToOffice">
          <Popconfirm
            placement="topLeft"
            title={"Are you sure to return office "}
            description={"description"}
            onConfirm={() => returnToOfficeAPI(id)}
            okText="Yes"
            cancelText="No"
          >
            <span>Return to Office</span>
          </Popconfirm>
        </Menu.Item>
      ) : null}
    </Menu>
  );

  // const card = (data) => {
  //   return (
  //     <div className="col-3 pl-0 assert-card-outer">
  //       <div className="card">
  //         <div className="card-head-new my-2 mx-3">
  //           <div>
  //             <Checkbox
  //               className="mr-2"
  //               onChange={(event) => onSelectingCheckbox(event, data)}
  //             />{" "}
  //             <span className="lap-assert">Laptop</span>
  //           </div>
  //           <Dropdown.Button overlay={menu(data.id)} trigger={["click"]} />
  //         </div>
  //         <div className="image-fixed-height">
  //           <img
  //             src="https://m.media-amazon.com/images/W/IMAGERENDERING_521856-T1/images/I/61bX2AoGj2L._SX522_.jpg"
  //             alt="device"
  //           ></img>
  //         </div>
  //         <div className="my-2 mx-3">
  //           <div className="mb-2">
  //             <span className="item-name2">Device Model No. -</span>
  //             <span className="brand-name2">{data.model}</span>
  //           </div>
  //           <div className="">
  //             <span className="item-name2">Device Brand -</span>
  //             <span className="brand-name2">{data.brand}</span>
  //           </div>
  //         </div>
  //         <div style={styles.lineBorder}></div>
  //         <div className="mb-2 mx-3 tag-contain">
  //           <Tag color={data.in_use ? "blue" : "red"}>
  //             {data.in_use ? "In use" : "Not in use"}
  //           </Tag>
  //           <Tag color={data.is_working ? "green" : "red"}>
  //             {data.is_working ? "Working" : "Not Working"}
  //           </Tag>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // const detailView = () => {
  //   return (
  //     <div>
  //       <div className="row ml-3 mr-1 my-4">
  //         {detailViewData.map((data) => {
  //           return <>{card(data)}</>;
  //         })}
  //       </div>
  //     </div>
  //   );
  // };

  const onCancel = () => {
    setActionModel(false);
  };

  const onComplete = () => {
    setActionModel(false);
    setCheckedItems([]);
    setCardsChecked(false);
    // isListView ? getListData(currentPage, currentSize) : getDetailViewData();
    getListData(currentPage, currentSize, null);
  };

  const getAllUsers = () => {
    axiosJSON
      .get("/api/user/")
      .then(({ data }) => {
        let obj = {};
        let users = [];
        data.data.map((user, index) => {
          obj = {
            employee_id: user.employee_id,
            id: user.id,
            name: user.name,
          };
          users.push(obj);
          return null;
        });
        setUserList(users);
      })
      .catch((error) => {
        console.log("-->>",error)
        message.error(error)
      });
  };

  return (
    <>
      <Spin spinning={loading}>
        <div className="col-12 pt-4">
          <div className="row m-0 headDir space-bet mb-3 pb-4">
            <div className="row m-0 back2">
              <BsArrowLeftCircle
                className="back-btn mr-4"
                onClick={() => {
                  props.history.push("/settings");
                  props.changeSawDrawer(true);
                }}
              />
              <div className="assetsdr">
                <BiDevices className="mr-2" />
                <div className="assert-head">Assets Directory</div>
              </div>
            </div>
            <div className="pr-2 ml-4">
              <Select
                showSearch
                allowClear={true}
                notFoundContent={null}
                value={employee}
                placeholder="Search Employee"
                style={{ width: "200px" }}
                onChange={(userId) => {
                  // if (isListView) {
                  // list API call
                  setEmployee(userId);
                  getListData(1, 10, userId);
                  setCurrentPage(1);
                  setCurrentSize(10);
                  // }
                  // else {
                  //   // call detail API
                  //   if (userId !== undefined) {
                  //     getDetailViewData(userId);
                  //   } else {
                  //     setDetailViewData([]);
                  //   }
                  // }
                }}
                optionFilterProp="children"
              >
                {userList.map((user) => (
                  <Select.Option key={user.id} value={user.id}>
                    {user.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
          <div className="row m-0 seprate-dir">
            <div className="pr-2"></div>
            {/* <div className="pr-2">
              <Button
                className="teamButton team2"
                type={isListView ? "primary" :"default"}
                onClick={() => {
                  setIsListView(!isListView);
                  setEmployee(null);
                  setFilterObject({});
                  setDetailViewData([]);
                }}
              >
                Tabular View
              </Button>
              <Button
                type={!isListView ? "primary" :"default"}
                className="recruterButton team2"
                onClick={() => {
                  setIsListView(!isListView);
                  setEmployee(null);
                  setFilterObject({});
                  setDetailViewData([]);
                }}
              >
                Detail View
              </Button>
            </div> */}

            <div className="row mb-3">
              {cardsChecked ? (
                <>
                  <div className="pr-3">
                    <Select
                      className="wid-150"
                      onChange={(value) => {
                        if (value === "moveToEmployee") {
                          setActionModel(true);
                        }
                      }}
                      allowClear
                      placeholder="Status"
                      value={filterObject.status}
                    >
                      <Select.Option key="1" value={"moveToEmployee"}>
                        Move to Employee
                      </Select.Option>
                      <Select.Option key="2" value={"moveToOffice"}>
                        <Popconfirm
                          placement="topLeft"
                          title={"Are you sure to return office "}
                          description={"description"}
                          onConfirm={() => returnToOfficeAPI()}
                          okText="Yes"
                          cancelText="No"
                        >
                          Return to Office
                        </Popconfirm>
                      </Select.Option>
                    </Select>
                  </div>
                </>
              ) : null}

              <div className="pr-3">
                <Select
                  className="wid-150"
                  onChange={(value) => {
                    setFilterObject((preState) => {
                      return {
                        ...preState,
                        deviceType: value,
                      };
                    });
                  }}
                  placeholder="Device Type"
                  allowClear
                  value={filterObject.deviceType}
                >
                  {deviceTypeOptions.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="pr-3">
                <Select
                  className="wid-150"
                  onChange={(value) => {
                    setFilterObject((preState) => ({
                      ...preState,
                      brand: value,
                    }));
                  }}
                  style={{ overflowY: "auto" }}
                  allowClear
                  placeholder="Device Brand"
                  mode="multiple"
                  value={filterObject.brand}
                >
                  {brandOptions.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="pr-3">
                <Select
                  className="wid-150"
                  onChange={(value) => {
                    setFilterObject((preState) => ({
                      ...preState,
                      status: value,
                    }));
                  }}
                  allowClear
                  placeholder="Status"
                  value={filterObject.status}
                >
                  <Select.Option key="1" value={"in_use"}>
                    In use
                  </Select.Option>
                  <Select.Option key="2" value={"not_in_use"}>
                    Not use
                  </Select.Option>
                </Select>
              </div>
              <div className="pr-3">
                <Select
                  className="wid-150"
                  onChange={(value) => {
                    setFilterObject((preState) => ({
                      ...preState,
                      condition: value,
                    }));
                  }}
                  allowClear
                  placeholder="Condition"
                  value={filterObject.condition}
                >
                  <Select.Option key="1" value="working">
                    Working
                  </Select.Option>
                  <Select.Option key="2" value="not_working">
                    Not Working
                  </Select.Option>
                </Select>
              </div>
            </div>
          </div>
          {/* {isListView ? listView : detailView()} */}
          {listView}
          {actionModel ? (
            <Modal
              title="Move to Employee"
              visible={actionModel}
              footer={null}
              onCancel={() => onCancel()}
            >
              <ActionModal
                closeModal={onCancel}
                onComplete={onComplete}
                selectedItems={checkedItems}
                userList={userList}
                props={props}
              />
            </Modal>
          ) : null}
        </div>
      </Spin>
    </>
  );
};

// const styles = {
//   lineBorder: {
//     width: "100%",
//     height: "1px",
//     marginTop: "5px",
//     marginBottom: "15px",
//     background: "#d9d9d9",
//   },
// };

export default withRouter(AssetsDirectory);
