import React from "react";
import { Button, Input, Form, DatePicker, message, Select } from "antd";
import axiosJSON from "../../util/SetHeaderAPI";
import moment from "moment";

const FormItem = Form.Item;
const { Option } = Select;
const dateFormat = "YYYY-MM-DD";
export default class AddEducation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [
        {
          name: "major",
          value: "",
        },
        {
          name: "remark",
          value: "",
        },
        {
          name: "start_date",
          value: "",
        },
        {
          name: "edu_type",
          value: "",
        },
        {
          name: "org_name",
          value: "",
        },
        {
          name: "city",
          value: "",
        },
      ],
      candidate_id: this.props.id,
      education_id: "",
      edu_type_list: [],
    };
    this.Mounted = false;
  }
  componentWillUnmount() {
    this.Mounted = false;
  }

  componentDidMount() {
    this.Mounted = true;
    if (this.props.edit) {
      let fields = [
        {
          name: "major",
          value: this.props.data.major,
        },
        {
          name: "remark",
          value: this.props.data.remark,
        },
        {
          name: "start_date",
          value:
            this.props.data.start_date !== null
              ? moment(this.props.data.start_date)
              : "",
        },
        {
          name: "org_name",
          value: this.props.data.org_name,
        },
        {
          name: "city",
          value: this.props.data.city,
        },
        {
          name: "edu_type",
          value: this.props.data.edu_type,
        },
      ];
      this.setState({
        fields: fields,
        candidate_id: this.props.data.candidate,
        education_id: this.props.data.id,
      });
    }
    axiosJSON
      .get("api/choice/?type=education")
      .then(({ data, status }) => {
        if (status === 200) {
          this.setState({ edu_type_list: data.data });
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  }

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  onFinish = (values) => {
    if (this.props.edit) {
      const body = values;
      body["candidate"] = parseInt(this.state.candidate_id);
      body["education_id"] = this.state.education_id;
      body["start_date"] = moment(body["start_date"]).format(dateFormat);
      axiosJSON
        .put(
          "api/candidate/" +
            this.state.candidate_id +
            "/education/" +
            this.state.education_id +
            "/",
          body
        )
        .then(({ data, status }) => {
          if (status === 202) {
            message.success("Candidate Education Edited!");
            this.props.closeModal();
          }
        })
        .catch(function (error) {
          message.error(error.message);
        });
    } else {
      const body = values;
      body["candidate"] = parseInt(this.state.candidate_id);
      body["start_date"] = moment(body["start_date"]).format(dateFormat);
      axiosJSON
        .post("api/candidate/" + this.state.candidate_id + "/education/", body)
        .then(({ data, status }) => {
          if (status === 201) {
            message.success("Candidate Education Added!");
            this.props.closeModal();
          }
        })
        .catch(function (error) {
          message.error(error.message);
        });
    }
  };

  render() {
    return (
      <div className="gx-login-container">
        <div className="gx-login-content education-form">
          <Form
            name="basic"
            fields={this.state.fields}
            onFieldsChange={(newFields) => this.setState({ fields: newFields })}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            className="gx-signin-form gx-form-row0"
          >
            <FormItem name="major" label="Education Major">
              <Input className="gx-input-lineheight" type="text" />
            </FormItem>

            <FormItem name="remark" label="Education Remark">
              <Input
                className="gx-input-lineheight"
                type="text"
                placeholder="Additional Remarks"
              />
            </FormItem>

            <FormItem
              name="start_date"
              label="Graduation Date"
              rules={[{ required: true, message: "Please enter Start Date!" }]}
            >
              <DatePicker placeholder="Education Start Date" />
            </FormItem>

            <FormItem name="city" label="State">
              <Input
                className="gx-input-lineheight"
                type="text"
                placeholder="Job Location"
              />
            </FormItem>

            <FormItem
              name="org_name"
              label="Education Org Name"
              rules={[
                { required: true, message: "Please enter Organization Name" },
              ]}
            >
              <Input
                className="gx-input-lineheight"
                placeholder="Education Org Name"
              />
            </FormItem>

            <FormItem
              name="edu_type"
              label="Degree Type"
              rules={[{ required: true, message: "Please select Degree Type" }]}
            >
              <Select>
                {this.state.edu_type_list.map((edu_type, index) => (
                  <Option key={index} value={edu_type.id}>
                    {edu_type.display_name}
                  </Option>
                ))}
              </Select>
            </FormItem>
            <FormItem>
              <Button type="primary" htmlType="submit">
                Submit Education
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }
}
