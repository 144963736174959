import React from "react";
import { Avatar, Dropdown, Tag, Tooltip } from "antd";
import moment from "moment-timezone";

const MeetingItems = ({ meeting, selectMeeting, menu }) => {
  return (
    <div className="main-meeting-box">
      <Dropdown.Button
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "20px",
        }}
        className="dropdown-button"
        overlay={menu(meeting)}
        trigger={["click"]}
      />
      <div
        className="gx-module-list-item"
        onClick={() => selectMeeting(meeting.id)}
      >
        <p className={`gx-text-truncate gx-ml-4 gx-text-hover`}>
          {meeting.candidate.name} ::{" "}
          {meeting.position ? meeting.position.display_name : null}
        </p>

        <p className={`gx-text-truncate gx-ml-4 gx-text-hover`}>
          <i className="icon icon-calendar-new" />{" "}
          {moment(new Date(meeting.start)).format("MM-DD-YYYY")}
        </p>

        <p className={`gx-text-truncate gx-ml-4 gx-text-hover`}>
          <i className="icon icon-schedule" />{" "}
          {new Date(meeting.start).toLocaleTimeString() +
            " to " +
            new Date(meeting.end).toLocaleTimeString()}
        </p>

        <div className="right-section">
          <ul>
            <li>
              <Tooltip title={meeting.created_by}>
                {meeting.created_by !== null ? (
                  meeting.created_by.split(" ").length >= 2 ? (
                    <Avatar className="gx-size-30 gx-pointer gx-mr-2">
                      {meeting.created_by
                        .split(" ")[0]
                        .charAt(0)
                        .toUpperCase() +
                        meeting.created_by
                          .split(" ")[1]
                          .charAt(0)
                          .toUpperCase()}
                    </Avatar>
                  ) : (
                    <Avatar className="gx-size-30 gx-pointer gx-mr-2">
                      {meeting.created_by.split(" ")[0].charAt(0).toUpperCase()}
                    </Avatar>
                  )
                ) : null}
              </Tooltip>
            </li>

            <li>
              <p className={`gx-text-truncate gx-pt-2 gx-text-hover gx-fs-sm`}>
                {moment(meeting.created_at).format("MMMM Do YYYY, h:mm A")}
              </p>
            </li>
          </ul>
        </div>

        <Tag className="btn-color-left gx-text-white gx-mr-0 gx-ml-4 gx-rounded-xxl gx-order-sm-2 cancelled_button">
          {meeting.status ? meeting.status : null}
        </Tag>

        {/*<div className="gx-task-item-content-left candidate-row-new">*/}
        {/*  <p*/}
        {/*    className={`gx-text-truncate gx-ml-4 gx-text-hover`}>*/}
        {/*    {new Date(meeting.start).toLocaleDateString()}</p>*/}

        {/*  <p className={`gx-text-truncate gx-ml-4 gx-text-hover`}>{new Date(meeting.start).toLocaleTimeString()*/}
        {/*    +" to "+new Date(meeting.end).toLocaleTimeString()}*/}
        {/*  </p>*/}
        {/*</div>*/}
        {/*<div className="gx-task-item-content-left candidate-row-new">*/}
        {/*  <p*/}
        {/*    className={`gx-text-truncate gx-ml-4 gx-text-hover`}>{meeting.created_at}</p>*/}
        {/*</div>*/}
        {/*<div className="gx-task-item-content-left candidate-row-new">*/}
        {/*  <p*/}
        {/*    className={`gx-text-truncate gx-ml-4 gx-text-hover`}>{meeting.created_by}</p>*/}
        {/*</div>*/}

        {/*<div className="gx-task-item-content-right candidate-row-new">*/}
        {/*  <Tag className="btn-color-left gx-text-white gx-mr-0 gx-ml-4 gx-rounded-xxl gx-order-sm-2">{meeting.position ? meeting.position.display_name : null}</Tag>*/}

        {/*</div>*/}

        {/*<div className="gx-task-item-content-right candidate-row-new">*/}
        {/*   <Tag className="btn-color-left gx-text-white gx-mr-0 gx-ml-4 gx-rounded-xxl gx-order-sm-2">{meeting.status? meeting.status : null}</Tag>*/}
        {/* </div>*/}
      </div>
    </div>
  );
};

export default MeetingItems;
