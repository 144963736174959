import React, { Component } from "react";
import { Col, Row, Comment, Avatar, Button, message } from "antd";
import Widget from "../../../components/Widget";
import axiosJSON from "../../../util/SetHeaderAPI";
import { connect } from "react-redux";
import SlateEditor from "../../../components/CommentTagEditor";
import { serialize } from "../../../components/Editor/parseJson";
import "nprogress/nprogress.css";
import nprogress from "nprogress";
import moment from "moment-timezone";

const beforeMatch = /^@(\w+)$/;
const beforeStr = "@";
const afterStr = "";
const Editor = ({
  onChange,
  onSubmit,
  submitting,
  value,
  onSelectUser,
  user_list,
}) => (
  <div className="comment-editer">
    {
      <SlateEditor
        beforeMatch={beforeMatch}
        afterStr={afterStr}
        beforeStr={beforeStr}
        onSelectUser={onSelectUser}
        value={value}
        setData={onChange}
        user_list={user_list}
      />
    }

    <Button
      className="comment-button"
      htmlType="submit"
      loading={submitting}
      onClick={onSubmit}
      type="primary"
    >
      Add Comment
    </Button>
  </div>
);

class CommentList extends Component {
  constructor(props) {
    super(props);
    const splitArray = window.location.href.split("/");
    this.id = splitArray[splitArray.length - 2];
    this.state = {
      comments: [],
      user_list: [],
      submitting: false,
      childSubmitting: false,
      parent: [
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
      ],
      childComment: [
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
      ],
      openEditor: true,
      openChildEditor: [false],
      parentId: null,
      selected_user: [],
      dateTime: "",
    };
    this.Mounted = false;
  }
  componentWillUnmount() {
    this.Mounted = false;
  }

  componentDidMount() {
    nprogress.start();
    this.Mounted = true;
    this.getCommentList();
    this.getUserList();
    nprogress.done();
  }

  getUserList = () => {
    axiosJSON
      .get("api/user/tags/")
      .then(({ data, status }) => {
        if (status === 200) {
          this.setState({
            user_list: data.data,
          });
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  getCommentList = () => {
    // const id = this.props.history.location.pathname.split("/")[2];
    axiosJSON
      .get("api/comment/?id=" + this.id + "&model=candidate")
      .then(({ data, status }) => {
        if (status === 200) {
          this.setState({
            comments: data.data,
          });
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };
  onSelectUser = (value) => {
    let user = this.state.selected_user;
    user.push(value);
    this.setState({
      selected_user: user,
    });
  };
  handleChange = (e) => {
    this.setState({
      parent: e,
    });
  };
  handleChildSubmit = (i, id) => {
    // const object_id = this.props.history.location.pathname.split("/")[2];
    if (!this.state.childComment) {
      return;
    }

    this.setState({
      childSubmitting: true,
    });

    let eid = [];
    this.state.selected_user.map((user) => {
      let i = this.state.user_list.find((u) => u.username === user.username);
      eid.push(i.id);
      return null;
    });

    const body = {
      object_id: this.id,
      model: "candidate",
      comment_text: serialize(this.state.childComment),
      parent_comment: id,
      tagged_user: eid,
    };

    axiosJSON
      .post("api/comment/", body)
      .then(({ data, status }) => {
        if (status === 201) {
          setTimeout(() => {
            this.setState(
              {
                childSubmitting: false,
                childComment: [
                  {
                    type: "paragraph",
                    children: [{ text: "" }],
                  },
                ],
                openChildEditor: [false],
                selected_user: [],
              },
              () => this.getCommentList()
            );
          }, 1000);
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };
  handleSubmit = (i) => {
    let text = serialize(this.state.parent);
    // const object_id = this.props.history.location.pathname.split("/")[2];
    if (!this.state.parent) {
      return;
    }
    let eid = [];
    this.state.selected_user.map((user) => {
      let i = this.state.user_list.find((u) => u.username === user.username);
      eid.push(i.id);
      return null;
    });
    this.setState({
      submitting: true,
    });
    const body = {
      object_id: this.id,
      model: "candidate",
      comment_text: text,
      parent_comment: "",
      tagged_user: eid,
    };
    axiosJSON
      .post("api/comment/", body)
      .then(({ data, status }) => {
        if (status === 201) {
          setTimeout(() => {
            this.state.comments.push(data.data);
            this.setState(
              {
                submitting: false,
                parent: "",
                openEditor: false,
                selected_user: [],
              },
              () => this.getCommentList()
            );
          }, 1000);
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };
  handleChildChange = (e) => {
    this.setState({
      childComment: e,
    });
  };
  changeCommentOpenEditor = (i) => {
    let arr = [false];
    arr[i] = true;
    this.setState({
      openEditor: true,
      parent: [
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
      ],
    });
  };
  changeChildCommentOpenEditor = (i, comment_id, comment) => {
    let arr = [false];
    arr[i] = true;
    this.setState({ openChildEditor: arr, parentId: comment_id });
  };
  // dateToTime = (date) =>
  //   date.toLocaleString("en-US", {
  //     hour: "numeric",
  //     minute: "numeric",
  //   });
  // setDateTime = (date) => {
  //   this.setState({
  //     dateTime: date,
  //   });
  // };

  render() {
    // const dateString = "2023-03-07T08:27:00Z";
    // const userOffset = new Date().getTimezoneOffset() * 60 * 1000;
    // const offset = new Date().getTimezoneOffset();
    // const localDate = new Date(dateString);
    // const utcDate = new Date(localDate.getTime() + userOffset);

    // console.log(
    //   `${this.dateToTime(utcDate)} (${this.dateToTime(localDate)} Your Time)`
    // );

    const { comments } = this.state;
    return (
      <div className="gx-profile-content">
        <Row>
          <Col xl={16} lg={14} md={14} sm={24} xs={24}>
            <Widget title="Comment" styleName="gx-card-profile">
              <div>
                {comments.length > 0 &&
                  comments.map((comment, i) => (
                    <Comment
                      key={comment.id}
                      actions={[
                        <span
                          key="comment-nested-reply-to"
                          onClick={() =>
                            this.changeChildCommentOpenEditor(
                              i,
                              comment.parent_comment
                            )
                          }
                        >
                          Reply to
                        </span>,
                      ]}
                      author={comment.user.name}
                      datetime={
                        comment.created
                          ? moment(new Date(comment.created))
                              .tz("America/New_York")
                              .format("YYYY-MM-DD HH:mm:ss")
                          : null
                        // new Date(comment.created).toDateString() +
                        // " " +
                        // new Date(comment.created).toLocaleTimeString()
                      }
                      avatar={
                        comment.user.name.split(" ")[1] !== undefined ? (
                          <Avatar
                            style={{
                              backgroundColor: "#3881bf",
                              color: "#fff",
                            }}
                          >
                            {comment.user.name
                              .split(" ")[0]
                              .charAt(0)
                              .toUpperCase() +
                              comment.user.name
                                .split(" ")[1]
                                .charAt(0)
                                .toUpperCase()}
                          </Avatar>
                        ) : (
                          <Avatar
                            style={{
                              backgroundColor: "#3881bf",
                              color: "#fff",
                            }}
                          >
                            {comment.user.name
                              .split(" ")[0]
                              .charAt(0)
                              .toUpperCase()}
                          </Avatar>
                        )
                      }
                      content={
                        <p
                          dangerouslySetInnerHTML={{
                            __html: comment.comment_text,
                          }}
                        />
                      }
                    >
                      {comment.child_comment.length > 0 &&
                        comment.child_comment.map((childComment, j) => (
                          <div key={j}>
                            <Comment
                              key={childComment.id}
                              author={childComment.user.name}
                              datetime={
                                comment.created
                                  ? moment(new Date(comment.created))
                                      .tz("America/New_York")
                                      .format("YYYY-MM-DD HH:mm:ss")
                                  : null
                                // new Date(comment.created).toDateString() +
                                // " " +
                                // new Date(comment.created).toLocaleTimeString()
                              }
                              avatar={
                                childComment.user.name.split(" ")[1] !==
                                undefined ? (
                                  <Avatar
                                    style={{
                                      backgroundColor: "#3881bf",
                                      color: "#fff",
                                    }}
                                  >
                                    {childComment.user.name
                                      .split(" ")[0]
                                      .charAt(0)
                                      .toUpperCase() +
                                      childComment.user.name
                                        .split(" ")[1]
                                        .charAt(0)
                                        .toUpperCase()}
                                  </Avatar>
                                ) : (
                                  <Avatar
                                    style={{
                                      backgroundColor: "#3881bf",
                                      color: "#fff",
                                    }}
                                  >
                                    {childComment.user.name
                                      .split(" ")[0]
                                      .charAt(0)
                                      .toUpperCase()}
                                  </Avatar>
                                )
                              }
                              content={
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: childComment.comment_text,
                                  }}
                                />
                              }
                            />
                          </div>
                        ))}
                      {this.state.openChildEditor[i] && (
                        <div>
                          <Comment
                            avatar={
                              this.props.auth ? (
                                this.props.auth.name.split(" ")[1] !==
                                undefined ? (
                                  <Avatar
                                    style={{
                                      backgroundColor: "#3881bf",
                                      color: "#fff",
                                    }}
                                  >
                                    {this.props.auth.name
                                      .split(" ")[0]
                                      .charAt(0)
                                      .toUpperCase() +
                                      this.props.auth.name
                                        .split(" ")[1]
                                        .charAt(0)
                                        .toUpperCase()}
                                  </Avatar>
                                ) : (
                                  <Avatar
                                    style={{
                                      backgroundColor: "#3881bf",
                                      color: "#fff",
                                    }}
                                  >
                                    {this.props.auth.name
                                      .split(" ")[0]
                                      .charAt(0)
                                      .toUpperCase()}
                                  </Avatar>
                                )
                              ) : null
                            }
                            author={this.props.auth.name}
                            content={this.state.openChildEditor[i]}
                          />
                          <Editor
                            onChange={this.handleChildChange}
                            onSubmit={() =>
                              this.handleChildSubmit(i, comment.id)
                            }
                            submitting={this.state.childSubmitting}
                            value={this.state.childComment}
                            user_list={this.state.user_list}
                            onSelectUser={this.onSelectUser}
                          />
                        </div>
                      )}
                    </Comment>
                  ))}
                {!this.state.openEditor && (
                  <Button
                    key="comment-nested-reply-to"
                    onClick={() => this.changeCommentOpenEditor()}
                  >
                    Add comment
                  </Button>
                )}
                {this.state.openEditor && (
                  <div>
                    <Comment
                      avatar={
                        this.props.auth ? (
                          this.props.auth.name.split(" ")[1] !== undefined ? (
                            <Avatar
                              style={{
                                backgroundColor: "#3881bf",
                                color: "#fff",
                              }}
                            >
                              {this.props.auth.name
                                .split(" ")[0]
                                .charAt(0)
                                .toUpperCase() +
                                this.props.auth.name
                                  .split(" ")[1]
                                  .charAt(0)
                                  .toUpperCase()}
                            </Avatar>
                          ) : (
                            <Avatar
                              style={{
                                backgroundColor: "#3881bf",
                                color: "#fff",
                              }}
                            >
                              {this.props.auth.name
                                .split(" ")[0]
                                .charAt(0)
                                .toUpperCase()}
                            </Avatar>
                          )
                        ) : null
                      }
                      author={this.props.auth.name}
                      content={this.state.openEditor}
                    />
                    {
                      <Editor
                        onChange={this.handleChange}
                        onSubmit={() => this.handleSubmit("")}
                        submitting={this.state.submitting}
                        value={this.state.parent}
                        user_list={this.state.user_list}
                        onSelectUser={this.onSelectUser}
                      />
                    }
                  </div>
                )}
              </div>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.authUser,
});

export default connect(mapStateToProps)(CommentList);
