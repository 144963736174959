import React, { Component } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Dropdown,
  Menu,
  message,
  Pagination,
  Select,
  Spin,
  Switch,
  Tooltip,
} from "antd";
import CustomScrollbars from "util/CustomScrollbars";
import IntlMessages from "util/IntlMessages";

import Auxiliary from "../../util/Auxiliary";
import { connect } from "react-redux";
import AddCandidate from "../../modals/Candidate/addCandidate";
import ResumeUploadDragger from "../../modals/Candidate/resumeuploaddragger";
import axiosJSON from "../../util/SetHeaderAPI";
import CandidateList from "./list";
import AppModuleHeader from "../../components/AppModuleHeader";
import ResumeFileViewer from "../../modals/Candidate/resumeFileViewer";
import AddSource from "../../modals/Candidate/addSource";
import CircularProgress from "../../components/CircularProgress";
import BulkEmailPreview from "../../modals/Candidate/bulkEmail";
import {
  setFilterObj,
  setPage,
  setSortBy,
  setAll,
  onSearch,
} from "../../appRedux/actions/Common";
import moment from "moment";
import BulkStageChange from "../../modals/Candidate/bulkStageChange";
import { withRouter } from "react-router";
import "nprogress/nprogress.css";
import nprogress from "nprogress";
import { CustomModal as Modal } from "../../util/CustomModal";
import AddBulkUsers from "../../modals/Candidate/addBulkUsers";
import SetReminder from "../../modals/Candidate/setReminder";
import {
  findFirstSundayNov,
  findSecondSundayMar,
} from "../../util/dstConverter";

const ITEM_HEIGHT = 34;

const actions = [
  //   {
  //   'key': 0,
  //   'label': 'Requalify',
  //   'action': 'requalify'
  // }, {
  //   'key': 1,
  //   'label': 'Disqualify',
  //   'action': 'disqualify'
  // },
  {
    key: 2,
    label: "Add source",
    action: "source",
  },
  {
    key: 3,
    label: "Follow",
    action: "follow",
  },
  {
    key: 4,
    label: "Unfollow",
    action: "unfollow",
  },
  {
    key: 5,
    label: "Bulk Email",
    action: "mail",
  },
  {
    key: 6,
    label: "Change Stage",
    action: "change_stage",
  },
];
const sortList = [
  {
    key: 0,
    value: "name",
    label: "Name",
  },
  {
    key: 1,
    value: "created",
    label: "Created Time",
  },
];

class Candidate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerState: false,
      selectedCandidates: 0,
      anchorEl: null,
      optionMenuState: false,
      user: {
        name: props.auth.name,
        email: props.auth.email,
      },
      query: "",
      addCandidateManually: false,
      addCandidateResume: false,
      filters: {},

      filterList: [
        {
          name: "Position",
          value: "position",
        },
        {
          name: "Stage",
          value: "stage",
        },
        {
          name: "Status",
          value: "status",
        },
        {
          name: "Source",
          value: "source",
        },
        {
          name: "Education",
          value: "education",
        },
      ],
      my: true,
      app: "",
      filterCollapsed: [true],
      candidateList: [],
      selectedCandidateList: [],
      currentCandidateList: null,
      optionName: "",
      filterObj: {},
      sortObj: "",
      loader: false,
      openFilterOption: [false],
      userQuery: "",
      userList: [],
      createdByUsers: [],
      evaluatedByUsers: [],
      resumeViewer: false,
      newCandidate: [],
      resume: {},
      resumeLoader: false,
      addSource: false,
      prevY: 0,
      page: 1,
      total: 0,
      bulk_email: false,
      templateList: [],
      file: null,
      changeStage: false,
      addBulkUsers: false,
      showEditReminder: false,
      tzStatus: false,
      showReminder: false,
      candidateToNotify: null,
      size: 10,
    };
    this.Mounted = false;
  }

  handleRequestClose = () => {
    this.setState(
      {
        candidateList: [],
        selectedCandidateList: [],
        selectedCandidates: 0,
        optionName: "",
        showMessage: false,
        addCandidateManually: false,
        addCandidateResume: false,
        addBulkUsers: false,
        labelMenuState: false,
        optionMenuState: false,
        resumeViewer: false,
        addSource: false,
        bulk_email: false,
        changeStage: false,
        married: false,
        showEditReminder: false,
        showReminder: false,
      },
      () =>
        this.updateCallback(
          1,
          [],
          this.state.filterObj,
          this.state.size,
          this.state.sortObj,
          this.state.my,
          this.state.query
        )
    );
  };

  getApp = () => {
    axiosJSON
      .get("/api/util/app/")
      .then(({ data, status }) => {
        this.setState({
          app: data.data,
        });
      })
      .catch((error) => message.error(error));
  };

  toggleFilterOption = (i) => {
    let openFilterOption = this.state.openFilterOption;
    openFilterOption[i] = !this.state.openFilterOption[i];
    this.setState({
      openFilterOption,
    });
  };

  ToDoSideBar = () => {
    return (
      <div className="gx-module-side custom_candiates">
        <div className="gx-module-side-header">
          <div className="gx-module-logo">
            <i className="icon icon-contacts gx-mr-4" />
            <IntlMessages id="sidebar.candidate" />
          </div>
        </div>
        <div className="gx-module-side-content">
          <CustomScrollbars>
            <div className="gx-module-add-task">
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item>
                      <Button
                        style={{ marginLeft: "10px" }}
                        // type="primary"
                        onClick={() => {
                          this.setState({ addCandidateManually: true });
                        }}
                      >
                        Add Manually
                      </Button>
                      <Button
                        style={{ marginLeft: "10px" }}
                        // type="primary"
                        onClick={() => {
                          this.setState({ addCandidateResume: true });
                        }}
                      >
                        <Tooltip title="Muliple resumes can be selected(Max. 5 at one time)">
                          Add Through resume
                        </Tooltip>
                      </Button>
                      {this.state.app === "india" && (
                        <Button
                          style={{ marginLeft: "10px" }}
                          // type="primary"
                          onClick={() => {
                            this.setState({ addBulkUsers: true });
                          }}
                        >
                          Add Bulk Candidates
                        </Button>
                      )}
                    </Menu.Item>
                    {/* <Menu.Divider /> */}
                    {/* {this.state.app === "india" && (
                      <Menu.Item>
                        You can add candidates in Bulk, Manually or with Resume
                      </Menu.Item>
                    )}
                    {this.state.app === "us" && (
                      <Menu.Item>
                        You can add candidates via Manually or with Resume
                      </Menu.Item>
                    )} */}
                  </Menu>
                }
                trigger={["click"]}
              >
                <Button
                  variant="raised"
                  type="primary"
                  className="gx-btn-block"
                >
                  <IntlMessages id="candidate.addCandidate" />{" "}
                </Button>
              </Dropdown>
            </div>
            <ul className="gx-module-nav new-side-nav">
              <li className="gx-module-nav-label">
                <IntlMessages id="candidate.filters" />

                {Object.keys(this.props.filterObj).length > 0 && (
                  // <span onClick={() => this.setState({filterObj: {}})} className="gx-text-blue gx-pointer"><em>Clear all filters</em></span>
                  <span
                    onClick={() => {
                      this.props.setFilterObj({});
                      this.setState({
                        filterObj: {},
                        openFilterOption: [false],
                      });
                      this.updateCallback(
                        1,
                        [],
                        {},
                        this.state.size,
                        this.state.sortObj,
                        this.state.my,
                        this.state.query
                      );
                    }}
                    className="gx-text-blue gx-pointer"
                  >
                    <em>Clear all filters</em>
                  </span>
                )}

                {this.state.filterList.map((filter, i) => (
                  <div
                    className="sidebar_new gx-pointer"
                    key={i}
                    onClick={() => this.toggleFilterOption(i)}
                  >
                    {filter.name}
                    {this.state.openFilterOption[i] ? (
                      <i className="icon icon-menu-up gx-pointer" />
                    ) : (
                      <i className="icon icon-menu-down gx-pointer" />
                    )}
                    {this.state.openFilterOption[i] && (
                      <div>{this.filterMenu(filter.value, i)}</div>
                    )}
                  </div>
                ))}

                <div
                  className="sidebar_new"
                  onClick={() => this.toggleFilterOption(6)}
                >
                  Assignment <i className="icon icon-menu-down" />
                  {this.state.openFilterOption[6] && (
                    <div>
                      <div>
                        <Checkbox
                          checked={
                            this.state.filterObj.hasOwnProperty("assignment") &&
                            this.state.filterObj["assignment"] === "assigned"
                          }
                          onChange={(e) =>
                            this.selectFilter(
                              e.target.checked,
                              "assigned",
                              "assignment",
                              6
                            )
                          }
                        />
                        Assigned
                      </div>
                      <div>
                        <Checkbox
                          checked={
                            this.state.filterObj.hasOwnProperty("assignment") &&
                            this.state.filterObj["assignment"] === "unassigned"
                          }
                          onChange={(e) =>
                            this.selectFilter(
                              e.target.checked,
                              "unassigned",
                              "assignment",
                              6
                            )
                          }
                        />
                        Unassigned
                      </div>
                      {this.state.filterObj.hasOwnProperty("assignment") && (
                        <div>
                          <Checkbox
                            checked={
                              this.state.filterObj.hasOwnProperty(
                                "assignment"
                              ) && this.state.filterObj["assignment"] === ""
                            }
                            onChange={(e) =>
                              this.selectFilter(false, "", "assignment", 6)
                            }
                          />
                          None
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div
                  className="sidebar_new"
                  onClick={() => this.toggleFilterOption(7)}
                >
                  Following <i className="icon icon-menu-down" />
                  {this.state.openFilterOption[7] && (
                    <div>
                      <div>
                        <Checkbox
                          checked={
                            this.state.filterObj.hasOwnProperty("follow") &&
                            this.state.filterObj["follow"] === true
                          }
                          onChange={(e) =>
                            this.selectFilter(
                              e.target.checked,
                              true,
                              "follow",
                              7
                            )
                          }
                        />
                        Following
                      </div>
                    </div>
                  )}
                </div>

                <div className="sidebar_new">
                  Recruited by <i className="icon icon-menu-down" />
                  <div>
                    <Select
                      showSearch
                      allowClear={true}
                      mode="multiple"
                      value={
                        this.state.filterObj.hasOwnProperty("recruited_by")
                          ? this.state.filterObj["recruited_by"]
                          : []
                      }
                      style={{ width: "100%" }}
                      placeholder="Select Recruited By"
                      onChange={(value) => this.selectCreatedByUser(value, 7)}
                      // onSearch={this.getUserList}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.userList.map((user, i) => (
                        <Select.Option key={i} value={user.id}>
                          {user.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div
                  className="sidebar_new"
                  onClick={() => this.toggleFilterOption(8)}
                >
                  Evaluated by <i className="icon icon-menu-down" />
                  <div>
                    <Select
                      showSearch
                      allowClear={true}
                      value={
                        this.state.filterObj.hasOwnProperty("evaluated_by")
                          ? this.state.filterObj["evaluated_by"]
                          : []
                      }
                      mode="multiple"
                      style={{ width: "100%" }}
                      placeholder="Select Evaluated By"
                      onChange={(value) => this.selectEvaluatedByUser(value, 8)}
                      // onSearch={this.getUserList}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.userList.map((user, i) => (
                        <Select.Option value={user.id}>
                          {user.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="sidebar_new">
                  Year of Birth
                  <div>
                    <div>
                      Less Than
                      <DatePicker
                        className="sidebar-datepicker"
                        onChange={(date, dateString) => {
                          if (
                            date === null &&
                            this.state.filterObj.hasOwnProperty("dob") &&
                            this.state.filterObj["dob"]["gte"] === ""
                          ) {
                            this.selectFilter(false, "", "dob");
                          } else {
                            this.selectFilter(true, dateString, "lte");
                          }
                        }}
                        picker="year"
                        value={
                          this.state.filterObj.hasOwnProperty("dob") &&
                          this.state.filterObj["dob"]["lte"]
                            ? moment(this.state.filterObj["dob"]["lte"])
                            : null
                        }
                      />
                    </div>
                    <div>
                      Greater Than
                      <DatePicker
                        className="sidebar-datepicker"
                        onChange={(date, dateString) => {
                          if (
                            date === null &&
                            this.state.filterObj.hasOwnProperty("dob") &&
                            this.state.filterObj["dob"]["lte"] === ""
                          ) {
                            this.selectFilter(false, "", "dob");
                          } else {
                            this.selectFilter(true, dateString, "gte");
                          }
                        }}
                        picker="year"
                        value={
                          this.state.filterObj.hasOwnProperty("dob") &&
                          this.state.filterObj["dob"]["gte"]
                            ? moment(this.state.filterObj["dob"]["gte"])
                            : null
                        }
                      />
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </CustomScrollbars>
        </div>
      </div>
    );
  };

  onOptionMenuItemSelect = (e, selectedCandidates) => {
    switch (e.key) {
      case "requalify":
        console.log("requalify modal");
        break;
      case "disqualify":
        console.log("disqualify modal");
        break;
      case "source":
        this.setState({ addSource: true });
        break;
      case "follow":
        this.followCandidate(selectedCandidates);
        break;
      case "unfollow":
        this.unfollowCandidate(selectedCandidates);
        break;
      case "mail":
        this.onBulkMailSend(selectedCandidates);
        break;
      case "change_stage":
        this.setState({ changeStage: true });
        break;
      default:
        break;
    }
  };
  onBulkMailSend = () => {
    axiosJSON
      .get("api/candidate/bulk_template/")
      .then(({ data, status }) => {
        this.setState({
          templateList: data.data,
          bulk_email: true,
        });
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  onBulkStageChange = (selectedCandidateList) => {};

  followCandidate = () => {
    const body = {
      candidates: this.state.selectedCandidateList,
    };
    axiosJSON
      .put("api/candidate/follow/", body)
      .then(({ data, status }) => {
        if (status === 202) {
          this.setState(
            {
              selectedCandidateList: [],
              selectedCandidates: 0,
              optionName: "",
              candidateList: [],
              total: 0,
            },
            () =>
              this.updateCallback(
                this.state.page,
                [],
                this.state.filterObj,
                this.state.size,
                this.state.sortObj,
                this.state.my,
                this.state.query
              )
          );

          message.success(data.message);
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };
  unfollowCandidate = () => {
    const body = {
      candidates: this.state.selectedCandidateList,
    };
    axiosJSON
      .put("api/candidate/unfollow/", body)
      .then(({ data, status }) => {
        if (status === 202) {
          this.setState(
            {
              selectedCandidateList: [],
              selectedCandidates: 0,
              optionName: "",
              candidateList: [],
              total: 0,
            },
            () =>
              this.updateCallback(
                this.state.page,
                [],
                this.state.filterObj,
                this.state.size,
                this.state.sortObj,
                this.state.my,
                this.state.query
              )
          );

          message.success(data.message);
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  onFollow = (e, val, id) => {
    e.stopPropagation();
    this.setState(
      {
        selectedCandidateList: [id],
      },
      () => {
        val ? this.followCandidate() : this.unfollowCandidate();
      }
    );
  };

  optionMenu = (selectedCandidates) => {
    return (
      <Menu
        id="option-menu"
        onClick={(e) => this.onOptionMenuItemSelect(e, selectedCandidates)}
        onClose={this.handleRequestClose}
        style={{ maxHeight: ITEM_HEIGHT * 5.5 }}
      >
        {actions.map((ele, i) => (
          <Menu.Item key={ele.action}>{ele.label}</Menu.Item>
        ))}
      </Menu>
    );
  };

  updateCallback = (page, arr, filterObj, size, sortObj, my, query) => {
    nprogress.start();
    this.setState({ loader: true });
    let myString = "";
    if (!my) {
      myString = "all";
    } else {
      myString = "my";
    }
    axiosJSON
      .get(
        "/api/candidate/?page=" +
          page +
          "&recruited_by=" +
          myString +
          "&filter_json=" +
          JSON.stringify(filterObj) +
          "&query=" +
          query +
          "&sort_by=" +
          sortObj +
          "&page_size=" +
          size
      )
      .then(({ data, status }) => {
        if (status === 200) {
          let candidateList = arr;
          let candidateObj = {};
          data.data.map((ele, i) => {
            candidateObj = {
              id: ele.id,
              name: ele.name,
              email: ele.email,
              source: ele.source,
              stage: ele.stage,
              status: ele.status,
              recruiter: ele.recruiter,
              selected: false,
              followers: ele.followers,
              follow_up_date: ele.follow_up_date,
            };
            candidateList.push(candidateObj);
            return null;
          });
          // const updatedList=candidateList
          //   .map(e => e['id'])
          //
          //   // store the keys of the unique objects
          //   .map((e, i, final) => final.indexOf(e) === i && i)
          //
          //   // eliminate the dead keys & store unique objects
          //   .filter(e => candidateList[e]).map(e => candidateList[e]);
          this.setState({
            candidateList: candidateList,
            page: page,
            total: data.total,
            loader: false,
            // sortObj: sortObj,
            // my: my,
          });
          this.props.setPage({ page: page, size: size });
          this.props.setFilterObj(filterObj);
          this.props.setAll(myString);
          this.props.setSortBy(sortObj);
          this.props.onSearch(query);
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
    nprogress.done();
  };
  getUserList = (query) => {
    this.setState({
      userList: [],
    });
    axiosJSON
      .get("/api/user/?team_name&query=" + query)
      .then(({ data, status }) => {
        if (status === 200) {
          this.setState({
            userList: data.data,
          });
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };
  selectSortBy = (value) => {
    this.setState(
      {
        sortObj: value,
        page: 1,
        candidateList: [],
        total: 0,
      },
      () =>
        this.updateCallback(
          this.state.page,
          [],
          this.state.filterObj,
          this.state.size,
          value,
          this.state.my,
          this.state.query
        )
    );
  };
  selectCreatedByUser = (value, i) => {
    if (value.length === 0) {
      this.selectFilter(false, value, "recruited_by", i);
    } else this.selectFilter(true, value, "recruited_by", i);
  };
  selectEvaluatedByUser = (value, i) => {
    if (value.length === 0) {
      this.selectFilter(false, value, "evaluated_by", i);
    } else this.selectFilter(true, value, "evaluated_by", i);
  };
  selectFilter = (checked, obj, type, i) => {
    let filterObj = this.state.filterObj;
    let o = this.state.filterObj;
    if (checked) {
      if (o.hasOwnProperty(type)) {
        if (type === "assignment" || type === "follow") {
          o[type] = obj;
        } else if (type === "recruited_by" || type === "evaluated_by") {
          o[type] = [];
          o[type] = obj;
        } else {
          o[type].push(obj);
        }
      } else if (type === "assignment" || type === "follow") {
        o[type] = obj;
        filterObj = o;
      } else if (type === "recruited_by" || type === "evaluated_by") {
        o[type] = obj;
        filterObj = o;
      } else if (type === "lte") {
        let o2 = o;
        o2 = {
          ...o2,
          dob: {
            lte: obj,
            gte:
              o2.hasOwnProperty("dob") && o2.dob.hasOwnProperty("gte")
                ? o2.dob.gte
                : "",
          },
        };
        filterObj = o2;
      } else if (type === "gte") {
        let o2 = o;
        o2 = {
          ...o2,
          dob: {
            lte:
              o2.hasOwnProperty("dob") && o2.dob.hasOwnProperty("lte")
                ? o2.dob.lte
                : "",
            gte: obj,
          },
        };
        filterObj = o2;
      } else {
        o[type] = [obj];
        filterObj = o;
      }
    } else {
      if (o.hasOwnProperty(type)) {
        if (
          type === "assignment" ||
          type === "follow" ||
          type === "recruited_by" ||
          type === "evaluated_by" ||
          type === "dob"
        ) {
          delete o[type];
        } else {
          var index = o[type].indexOf(obj);
          if (index !== -1) o[type].splice(index, 1);
          if (o[type].length === 0) delete o[type];
        }
      }
    }
    let openFilterOption = this.state.openFilterOption;
    openFilterOption[i] = true;
    // this.props.setFilterObj(filterObj)
    this.setState(
      {
        filterObj: filterObj,
        candidateList: [],
        page: 1,
        total: 0,
        openFilterOption,
      },
      () =>
        this.updateCallback(
          this.state.page,
          [],
          filterObj,
          this.state.size,
          this.state.sortObj,
          this.state.my,
          this.state.query
        )
    );
  };

  filterMenu = (filter, k) => {
    var filterList = Object.keys(this.state.filters);
    var options;
    filterList.map((ele, i) => {
      if (ele === filter) {
        options = this.state.filters[ele].map((filterObj, j) => (
          <div key={j}>
            <Checkbox
              checked={
                this.state.filterObj.hasOwnProperty(filter) &&
                this.state.filterObj[filter].includes(filterObj.id)
              }
              onChange={(e) =>
                this.selectFilter(e.target.checked, filterObj.id, filter, k)
              }
            />
            {filterObj.display_name}
          </div>
        ));
      }
      return null;
    });
    return options;
  };

  componentWillUnmount() {
    this.Mounted = false;
  }

  componentDidMount() {
    this.getApp();
    nprogress.start();
    this.Mounted = true;
    this.getFilters();

    this.setState({
      filterObj: this.props.filterObj,
      page: this.props.pageObj ? this.props.pageObj.page : 1,
      size: this.props.pageObj ? this.props.pageObj.size : 10,
      sortObj: this.props.sortBy,
      my: this.props.all === "my" ? true : false,
      query: this.props.query,
    });
    this.getCandidate(
      this.props.pageObj ? this.props.pageObj.page : 1,
      this.props.filterObj,
      this.props.pageObj ? this.props.pageObj.size : 10,
      this.props.sortBy,
      this.props.all === "my" ? true : false,
      this.props.query
    );
    this.getUserList("");
    // var options = {
    //   root: null,
    //   rootMargin: "0px",
    //   threshold: 1.0,
    // };
    // this.observer = new IntersectionObserver(
    //   this.handleObserver.bind(this),
    //   options
    // );
    //this.observer.observe(this.loadingRef);
    nprogress.done();
  }

  // handleObserver(entities, observer) {
  //   const y = entities[0].boundingClientRect.y;
  //   let t = parseInt(this.state.total) / 10;
  //   if (Math.ceil(t) >= this.state.page && this.state.prevY > y) {
  //     const curPage = this.state.page + 1;
  //     this.getCandidate(curPage, this.props.filterObj);
  //     this.setState({ page: curPage });
  //   }
  //   this.setState({ prevY: y });
  // }

  getCandidate = (page, filterObj, size, sortBy, myString, query) => {
    this.updateCallback(
      page,
      this.state.candidateList,
      filterObj,
      size,
      sortBy,
      myString,
      query
    );
  };

  getFilters = () => {
    axiosJSON
      .get("/api/candidate/filter/")
      .then(({ data, status }) => {
        if (status === 200) {
          this.setState({
            filters: data.data,
          });
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };
  onSelectAllCandidate = (data) => {
    let obj = {};
    let candidateList = [];
    let selectedCandidates = [];
    if (data.target.checked) {
      candidateList = this.state.candidateList.map((candidate) => {
        obj = {
          ...candidate,
          selected: true,
        };
        selectedCandidates.push(candidate.id);
        return obj;
      });
    } else {
      candidateList = this.state.candidateList.map((candidate) => {
        obj = {
          ...candidate,
          selected: false,
        };
        let index = selectedCandidates.indexOf(candidate.id);
        if (index > -1) {
          selectedCandidates.splice(index, 1);
        }
        return obj;
      });
    }

    this.setState({
      candidateList: candidateList,
      selectedCandidateList: selectedCandidates,
      selectedCandidates: data.target.checked ? candidateList.length : 0,
      optionName: data.target.checked ? "All" : "",
    });
  };
  onCandidateSelected = (data) => {
    data.selected = !data.selected;
    let selectedCandidates = this.state.selectedCandidates;
    let optionName;
    const candidateList = this.state.selectedCandidateList;
    selectedCandidates >= 0 &&
      this.state.candidateList.map((candidate) => {
        if (candidate.id === data.id) {
          if (candidate.selected) {
            selectedCandidates++;
            candidateList.push(data.id);
          } else {
            var index = candidateList.indexOf(candidate.id);
            if (index !== -1) candidateList.splice(index, 1);
            selectedCandidates--;
          }
        }
        return null;
      });
    if (
      selectedCandidates > 0 &&
      selectedCandidates < this.state.candidateList.length
    ) {
      optionName = selectedCandidates + " Selected";
    } else if (!(selectedCandidates < this.state.candidateList.length)) {
      optionName = "All";
    } else {
      optionName = "";
    }
    this.setState({
      selectedCandidates: selectedCandidates,
      optionName: optionName,
      selectedCandidateList: candidateList,
    });
  };

  onToggleDrawer() {
    this.setState({
      drawerState: !this.state.drawerState,
    });
  }

  updateSearch = (e) => {
    let val = e.target.value;
    e.preventDefault();
    this.setState(
      {
        query: val,
        page: 1,
        candidateList: [],
        total: 0,
      },
      () =>
        this.updateCallback(
          this.state.page,
          [],
          this.state.filterObj,
          this.state.size,
          this.state.sortObj,
          this.state.my,
          val
        )
    );
  };

  onAllCandidateSelect() {
    //const selectAll = this.state.selectedCandidates < this.state.candidateList.length;
  }

  setResumeData = (data) => {
    data["isEdit"] = false;
    let arr = [...this.state.newCandidate, data];
    this.setState({
      // newCandidate: data.candidate,
      // resume: data.resume,
      newCandidate: arr,
      resumeViewer: true,
    });
  };

  changeResumeLoader = (flag) => {
    this.setState({
      resumeLoader: flag,
    });
  };

  selectCandidate = (id) => {
    this.props.history.push({
      pathname: "/candidate/" + id,
      params: { id: id },
    });
  };

  onFieldChange = (value) => {
    console.log(value);
  };

  onToggleChange = (checked) => {
    this.setState(
      {
        my: checked,
      },
      () =>
        this.updateCallback(
          1,
          [],
          this.state.filterObj,
          this.state.size,
          this.state.sortObj,
          checked,
          this.state.query
        )
    );
  };

  exportCandidates = () => {
    let myString = "";
    if (!this.state.my) {
      myString = "all";
    } else {
      myString = "my";
    }
    axiosJSON
      .get(
        "/api/candidate/export/?recruited_by=" +
          myString +
          "&filter_json=" +
          JSON.stringify(this.state.filterObj) +
          "&query=" +
          this.state.query +
          "&sort_by=" +
          this.state.sortObj,
        {
          responseType: "blob",
        }
      )
      .then(({ data, status }) => {
        var blobURL =
          window.URL && window.URL.createObjectURL
            ? window.URL.createObjectURL(data)
            : window.webkitURL.createObjectURL(data);
        var tempLink = document.createElement("a");
        tempLink.style.display = "none";
        tempLink.href = blobURL;
        tempLink.setAttribute(
          "download",
          "Candidate-details-" +
            moment(new Date()).format("DD_MMM_YYYY_HH_MM_ss") +
            ".csv"
        );

        if (typeof tempLink.download === "undefined") {
          tempLink.setAttribute("target", "_blank");
        }
        document.body.appendChild(tempLink);
        tempLink.click();

        setTimeout(function () {
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(blobURL);
        }, 200);
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  onPageChange = (page, size) => {
    // this.props.setPage({ page: page, size: size });
    this.setState({ page: page, size: size }, () =>
      this.updateCallback(
        page,
        [],
        this.state.filterObj,
        size,
        this.state.sortObj,
        this.state.my,
        this.state.query
      )
    );
  };

  checkTimezone = () => {
    var tz = new Date().getTimezoneOffset();
    let startOfEST;
    let endOfEST;
    endOfEST = findFirstSundayNov(new Date());
    startOfEST = findSecondSundayMar(new Date());
    if (startOfEST < new Date() && new Date() < endOfEST) {
      if (tz === 240) return false;
      else return true;
    } else if (tz === 300) {
      return false;
    } else {
      return true;
    }
  };

  onNotify = (e, id) => {
    e.stopPropagation();
    console.log(this.checkTimezone());
    if (!this.checkTimezone()) {
      this.setState({
        showReminder: true,
        candidateToNotify: id,
      });
    } else {
      message.error("Please change your timezone to EST!");
    }
  };

  onEditNotify = (e, id) => {
    e.stopPropagation();
    if (!this.checkTimezone()) {
      this.setState({
        showEditReminder: true,
        candidateToNotify: id,
      });
    } else {
      message.error("Please change your timezone to EST !");
    }
  };

  updateCandidate = (index) => {
    let arr = [...this.state.newCandidate];
    arr.splice(index, 1);
    this.setState({ newCandidate: arr }, () =>
      arr.length <= 0 ? this.handleRequestClose() : null
    );
  };

  setEdit = (candidate) => {
    let arr = [...this.state.newCandidate];
    let i = arr.indexOf(candidate);
    arr[i]["isEdit"] = true;
    this.setState({ newCandidate: arr });
  };

  render() {
    const {
      candidateList,
      loader,
      selectedCandidates,
      drawerState,
      alertMessage,
      showMessage,
      addCandidateManually,
      addCandidateResume,
      resumeViewer,
      addSource,
      bulk_email,
      changeStage,
      addBulkUsers,
      showEditReminder,
      showReminder,
    } = this.state;
    // const loadingCSS = {
    //   margin: "30px",
    // };
    const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
    return (
      <div className="gx-main-content">
        <div className="gx-app-module">
          <div className="gx-d-block gx-d-lg-none">
            <Drawer
              placement="left"
              closable={false}
              visible={drawerState}
              onClose={this.onToggleDrawer.bind(this)}
            >
              {this.ToDoSideBar()}
            </Drawer>
          </div>
          <div className="gx-module-sidenav gx-d-none gx-d-lg-flex">
            {this.ToDoSideBar()}
          </div>

          <div className="gx-module-box candidates_rightbox">
            <div className="gx-module-box-header">
              <span className="gx-drawer-btn gx-d-flex gx-d-lg-none">
                <i
                  className="icon icon-menu gx-icon-btn"
                  aria-label="Menu"
                  onClick={this.onToggleDrawer.bind(this)}
                />
              </span>
              <AppModuleHeader
                placeholder="Search Candidate by Name/PhoneNumber"
                user={this.state.user}
                onChange={this.updateSearch.bind(this)}
                value={this.state.query}
              />
            </div>
            <div className="gx-module-box-content">
              {this.state.currentCandidateList === null ? (
                <div className="gx-module-box-topbar gx-module-box-topbar-todo">
                  {this.state.candidateList.length > 0 ? (
                    <Auxiliary>
                      {selectedCandidates > 0 && (
                        <Checkbox
                          className="gx-icon-btn"
                          color="primary"
                          indeterminate={
                            selectedCandidates > 0 &&
                            selectedCandidates < candidateList.length
                          }
                          checked={selectedCandidates > 0}
                          onChange={this.onAllCandidateSelect.bind(this)}
                          value="SelectMail"
                        />
                      )}
                      {this.state.optionName}
                    </Auxiliary>
                  ) : null}
                  {selectedCandidates > 0 && (
                    <Dropdown
                      overlay={this.optionMenu(selectedCandidates)}
                      placement="bottomRight"
                      trigger={["click"]}
                    >
                      <Button className="new-dropdown">
                        <span className="gx-px-2">Actions</span>
                        <i className="icon icon-charvlet-down" />
                      </Button>
                    </Dropdown>
                  )}
                  All{" "}
                  <Switch
                    className="switch-new-button"
                    size="small"
                    checked={this.state.my}
                    onChange={this.onToggleChange}
                  />
                  {this.state.file !== null && (
                    <a href={this.state.file} download={true}>
                      download
                    </a>
                  )}
                  <Button
                    onClick={this.exportCandidates}
                    className="right-export-button gx-r-e-btn"
                  >
                    Export
                  </Button>
                  <Select
                    allowClear={true}
                    value={this.state.sortObj ? this.state.sortObj : null}
                    style={{ width: "15%" }}
                    className="right-button"
                    placeholder="Sort by"
                    onChange={this.selectSortBy}
                  >
                    {sortList.map((user, i) => (
                      <Select.Option key={i} value={user.value}>
                        {user.label}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              ) : (
                <div className="gx-module-box-topbar">
                  <i
                    className="icon icon-arrow-left gx-icon-btn"
                    onClick={() => {
                      this.setState({ currentCandidateList: null });
                    }}
                  />
                </div>
              )}

              {loader ? (
                <CircularProgress />
              ) : (
                <CandidateList
                  selectedCandidates={this.state.selectedCandidates}
                  selectCandidate={this.selectCandidate}
                  candidateList={candidateList}
                  onSelectAllCandidate={this.onSelectAllCandidate}
                  onCandidateSelected={this.onCandidateSelected}
                  onEditNotify={this.onEditNotify}
                  useDragHandle={true}
                  user={this.props.auth}
                  onFollow={this.onFollow}
                  onNotify={this.onNotify}
                  // followUpDate={followUpDate}
                />
              )}
              {candidateList.length > 0 && (
                <div className="Pagination-section mb-5 mr-4">
                  <Pagination
                    showSizeChanger
                    current={this.state.page}
                    total={this.state.total}
                    pageSize={this.state.size}
                    onChange={this.onPageChange}
                    onShowSizeChange={this.onPageChange}
                  />
                </div>
              )}
              {/* <div
                ref={(loadingRef) => (this.loadingRef = loadingRef)}
                style={loadingCSS}
              /> */}
              {candidateList.length > 0 ? (
                <span style={loadingTextCSS}>
                  <Spin />
                  Loading...
                </span>
              ) : (
                <span style={{ textAlign: "center" }}>No records found.</span>
              )}
            </div>
          </div>
        </div>
        {addCandidateManually && (
          <Modal
            title="Add candidate Manually"
            className="popupsize"
            visible={addCandidateManually}
            footer={null}
            onCancel={this.handleRequestClose.bind(this)}
          >
            <AddCandidate closeModal={this.handleRequestClose} />
          </Modal>
        )}
        {addCandidateResume && (
          <Modal
            title={null}
            className="upload-popup"
            closable={false}
            visible={addCandidateResume}
            footer={null}
            onCancel={this.handleRequestClose.bind(this)}
          >
            <ResumeUploadDragger
              resumeLoader={this.state.resumeLoader}
              changeResumeLoader={this.changeResumeLoader}
              setResumeData={this.setResumeData}
              closeModal={this.handleRequestClose}
            />
          </Modal>
        )}
        {addBulkUsers && (
          <Modal
            title="Add Bulk Candidates"
            className="bulk_user"
            closable={true}
            visible={addBulkUsers}
            footer={null}
            onCancel={this.handleRequestClose.bind(this)}
          >
            <AddBulkUsers closeModal={this.handleRequestClose} />
          </Modal>
        )}
        {resumeViewer && (
          <Modal
            title={null}
            className="upload-popup"
            closable={false}
            visible={resumeViewer}
            footer={null}
            onCancel={() => {
              this.setState({ newCandidate: [] });
              this.handleRequestClose();
            }}
          >
            {this.state.newCandidate.length > 0 && <ResumeFileViewer
              newCandidate={this.state.newCandidate}
              onFieldChange={this.onFieldChange}
              resume={this.state.resume}
              closeModal={this.handleRequestClose}
              updateCandidate={this.updateCandidate}
              setEdit={this.setEdit}
            />}
          </Modal>
        )}
        {addSource && (
          <Modal
            title="Add Source"
            visible={addSource}
            footer={null}
            onCancel={this.handleRequestClose.bind(this)}
          >
            <AddSource
              candidateList={this.state.selectedCandidateList}
              closeModal={this.handleRequestClose}
            />
          </Modal>
        )}
        {bulk_email && (
          <Modal
            title="Bulk Email Preview"
            visible={bulk_email}
            footer={null}
            className="mail_popup"
            onCancel={this.handleRequestClose.bind(this)}
          >
            <BulkEmailPreview
              selectedCandidatesList={this.state.selectedCandidateList}
              templateList={this.state.templateList}
              closeModal={this.handleRequestClose}
            />
          </Modal>
        )}
        {changeStage && (
          <Modal
            title="Bulk Stage Change"
            visible={changeStage}
            footer={null}
            onCancel={this.handleRequestClose.bind(this)}
          >
            <BulkStageChange
              selectedCandidatesList={this.state.selectedCandidateList}
              closeModal={this.handleRequestClose}
            />
          </Modal>
        )}
        {showReminder && (
          <Modal
            title="Schedule Reminder"
            visible={showReminder}
            footer={null}
            onCancel={this.handleRequestClose.bind(this)}
          >
            <SetReminder
              candidateId={this.state.candidateToNotify}
              closeModal={this.handleRequestClose}
              isEdit={false}
            />
          </Modal>
        )}
        {showEditReminder && (
          <Modal
            title="Edit scheduled Reminder"
            visible={showEditReminder}
            footer={null}
            onCancel={this.handleRequestClose.bind(this)}
          >
            <SetReminder
              candidateId={this.state.candidateToNotify}
              closeModal={this.handleRequestClose}
              isEdit={true}
            />
          </Modal>
        )}

        {showMessage &&
          message.info(
            <span id="message-id">{alertMessage}</span>,
            3,
            this.handleRequestClose
          )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setFilterObj: (data) => dispatch(setFilterObj(data)),
  setPage: (data) => dispatch(setPage(data)),
  setAll: (data) => dispatch(setAll(data)),
  setSortBy: (data) => dispatch(setSortBy(data)),
  onSearch: (data) => dispatch(onSearch(data)),
});

const mapStateToProps = (state) => ({
  auth: state.auth.authUser,
  filterObj: state.common.filterObj,
  pageObj: state.common.page,
  all: state.common.allCandidate,
  sortBy: state.common.sortBy,
  query: state.common.query,
});

const CandidateWithRouter = withRouter(Candidate);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateWithRouter);
