import React from "react";
import Widget from "components/Widget";
import { Tabs } from "antd";
import Resume from "./Resume";
import Experience from "./Experience";
import Education from "./Education";

const TabPane = Tabs.TabPane;

const ResumeSummary = (props) => {
  return (
    <Widget styleName="gx-card-profile">
      <div className="ant-card-head">
        <span className="ant-card-head-title gx-mb-2">Resume Summary</span>
      </div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Resume" key="1">
          <div className="gx-mb-2">
            <Resume id={props.id} data={props.data} update={props.update} />
          </div>
        </TabPane>
        <TabPane tab="Experience" key="2">
          <div className="gx-mb-2">
            <Experience id={props.id} />
          </div>
        </TabPane>
        <TabPane tab="Education/Skills" key="3">
          <div className="gx-mb-2">
            <Education id={props.id} />
          </div>
        </TabPane>
      </Tabs>
    </Widget>
  );
};

export default ResumeSummary;
