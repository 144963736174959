import React, { Component } from "react";
import { Col, message, Row, Timeline } from "antd";
import Widget from "../../../components/Widget";
import axiosJSON from "../../../util/SetHeaderAPI";
import moment from "moment-timezone";
import { ClockCircleOutlined } from "@ant-design/icons";
import "nprogress/nprogress.css";
import nprogress from "nprogress";

class Activity extends Component {
  constructor(props) {
    super(props);
    const splitArray = window.location.href.split("/");
    this.id = splitArray[splitArray.length - 2];
    this.state = {
      activity: [],
      prevY: 0,
      page: 1,
      recruiterHistory: [],
      page_history: 1,
    };
    this.Mounted = false;
  }

  handleObserver(entities, observer) {
    const y = entities[0].boundingClientRect.y;

    if (this.state.prevY > y) {
      const curPage = this.state.page + 1;
      // this.getActivity(curPage);
      this.setState({ page: curPage });
    }
    this.setState({ prevY: y });
  }
  getRecruiterHistory = (page) => {
    // const id = this.props.history.location.pathname.split("/")[2];
    axiosJSON
      .get("/api/candidate/" + this.id + "/recruiters/?page=" + page)
      .then(({ data, status }) => {
        if (status === 200) {
          let arr = this.state.recruiterHistory;
          data.data.map((ele) => arr.push(ele));
          this.setState({
            recruiterHistory: arr,
          });
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  getActivity = (page) => {
    // const id = this.props.history.location.pathname.split("/")[2];
    axiosJSON
      .get("api/candidate/" + this.id + "/activity/?page=" + page)
      .then(({ data, status }) => {
        if (status === 200) {
          let arr = this.state.activity;
          data.data.map((ele) => arr.push(ele));
          this.setState({
            activity: arr,
          });
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  componentWillUnmount() {
    this.Mounted = false;
  }

  componentDidMount() {
    nprogress.start();
    this.Mounted = true;
    var options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };
    this.getActivity(this.state.page);
    this.getRecruiterHistory(this.state.page_history);
    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      options
    );
    this.observer.observe(this.loadingRef);
    nprogress.done();
  }

  render() {
    const loadingCSS = {};
    const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
    return (
      <div className="gx-profile-content">
        <Row>
          <Col xl={16} lg={14} md={14} sm={24} xs={24}>
            <Widget title="Activity" styleName="gx-card-profile">
              {this.state.activity.length !== 0 ? (
                <Timeline>
                  {this.state.activity.map((item, index) => (
                    <Timeline.Item
                      key={item.id}
                      dot={
                        <ClockCircleOutlined
                          style={{
                            color:
                              item.activity_type === "created"
                                ? "#1A90FF"
                                : item.activity_type === "updated"
                                ? "green"
                                : "red",
                          }}
                        />
                      }
                      color={
                        item.activity_type === "created"
                          ? "#1A90FF"
                          : item.activity_type === "updated"
                          ? "green"
                          : "red"
                      }
                    >
                      {item.desc}
                      <p>
                        <span className="gx-fs-xs">
                          {item.created
                            ? moment(new Date(item.created))
                                .tz("America/New_York")
                                .format("YYYY-MM-DD HH:mm:ss")
                            : null}
                        </span>
                      </p>
                    </Timeline.Item>
                  ))}
                </Timeline>
              ) : (
                <span>No Activity</span>
              )}
              <div
                ref={(loadingRef) => (this.loadingRef = loadingRef)}
                style={loadingCSS}
              />
              <span style={loadingTextCSS}>Loading...</span>
            </Widget>
          </Col>
          <Col xl={8} lg={10} md={10} sm={24} xs={24}>
            <Widget title="Recruiter History" styleName="gx-card-profile">
              {this.state.recruiterHistory.length !== 0 ? (
                <Timeline>
                  {this.state.recruiterHistory.map((item, index) => (
                    <Timeline.Item
                      key={item.id}
                      dot={<ClockCircleOutlined style={{ color: "#1A90FF" }} />}
                      color={"#1A90FF"}
                    >
                      {item.user}
                      <p>
                        <span className="gx-fs-xs">
                          {item.start
                            ? moment(new Date(item.start))
                                .tz("America/New_York")
                                .format("YYYY-MM-DD HH:mm:ss")
                            : null}
                        </span>
                      </p>
                    </Timeline.Item>
                  ))}
                </Timeline>
              ) : (
                <span>No Recruiter History</span>
              )}
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Activity;
