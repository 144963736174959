import React, {useState, useEffect} from 'react';
import axiosJSON from "../../util/SetHeaderAPI";
import {Button, Form, message, Popconfirm, Select, Spin} from "antd";

const FormItem = Form.Item;

const BulkStageChange = (props) => {

  const [stageList, setStageList] = useState([])
  const [stage, setStage] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(()=> {
    getStageList()
  },[])

  const getStageList = () => {
    axiosJSON.get('api/choice/?type=stage')
      .then(({data, status}) => {
        if (status === 200) {
          setStageList(data.data)
        }
      }).catch(function (error) {
      message.error(error.message)
    })
  }

  const changeStage = () => {
    let body = {
      candidate_ids: props.selectedCandidatesList,
      stage_id: stage
    }
    setLoading(true)
    axiosJSON.put('api/candidate/bulk_stage/', body)
      .then(({data, status}) => {
        message.success(data.message)
        setLoading(false)
        props.closeModal()

      }).catch(function (error) {
      setLoading(false)
      props.closeModal()
      message.error(error.message)
    })
  }

  const setStageId = (value) => {
    setStage(value)
  }

  return (
    <div className="gx-login-content">
      <Spin spinning={loading}>
        <Form
          name="basic"
          // aria-disabled={!stage}
          className="gx-signin-form gx-form-row0">

          <FormItem>
            <label>Select Stage : </label>
            <Select
              showSearch
              style={{width: 293, marginLeft: '20px'}}
              placeholder="Select a Stage"
              optionFilterProp="children"
              onChange={(val)=>setStageId(val)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {stageList.length !== 0 &&
              stageList.map((source, i) =>
                <Select.Option key={i}
                               value={source.id}>{source.display_name}</Select.Option>
              )}
            </Select>

          </FormItem>


          <FormItem>
            <Popconfirm title={"Are you sure to change stage of multiple candidates ?"}
                        onConfirm={changeStage}
                        okText="Yes"
                        cancelText="No"
                        disabled={!stage}
            >
              <Button type="primary" disabled={!stage}>
                Submit
              </Button>
            </Popconfirm>

          </FormItem>
        </Form>
      </Spin>
    </div>
  )
}

export default BulkStageChange
