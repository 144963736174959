import React, { Component } from "react";
import { Row, Input, Select, Button, message } from "antd";
import axiosJSON from "../../../../util/Api";

class AddFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldNames: "",
      fieldType: "",
    };
  }

  onInputChange = (e) => {
    this.setState({
      fieldNames: e.target.value,
    });
  };

  onChange = (e) => {
    this.setState({
      fieldType: e,
    });
  };

  onSubmit = () => {
    let body = {
      name: this.state.fieldNames.toLowerCase().replace(" ", "_"),
      type: this.state.fieldType,
      display_name: this.state.fieldNames,
    };
    axiosJSON
      .post("/api/field/", body)
      .then(({ data, status }) => {
        if (status === 201) {
          message.success("Field added!");
          this.props.handleClose();
        } else {
          message.success(data.message);
          this.props.handleClose();
        }
      })
      .catch(function (error) {
        message.error(error.data.message);
        this.props.handleClose();
      });
  };

  render() {
    return (
      <div className="gx-login-container">
        <div className="gx-login-content experience-form">
          <Row>
            <div style={{ display: "inline-flex", marginBottom: "10px" }}>
              <ul>
                <li>
                  <span style={{ margin: "10px" }}>
                    {" "}
                    Enter Custom Field Name{" "}
                  </span>
                  <Input
                    className="gx-fs-md"
                    onChange={(e) => this.onInputChange(e)}
                    style={{ width: "100%" }}
                    placeholder="Enter Custom Field"
                  />
                </li>
                <br />
                <li>
                  <span style={{ margin: "10px" }}> Select Field Type </span>
                  <Select
                    className="form-field"
                    style={{ width: "100%" }}
                    placeholder={"Select Field Type"}
                    multiple
                    showSearch
                    //   value={value}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={this.onChange}
                  >
                    {/* {this.props.options.map((ele, i) => ( */}
                    <Select.Option value={"text"}>Text</Select.Option>
                    <Select.Option value={"longtext"}>Long Text</Select.Option>
                    <Select.Option value={"number"}>Number</Select.Option>
                    <Select.Option value={"datepicker"}>
                      Date Picker
                    </Select.Option>
                    <Select.Option value={"file"}>Upload File</Select.Option>
                    <Select.Option value={"select"}>Select</Select.Option>
                    {/* ))} */}
                  </Select>
                </li>
              </ul>
            </div>
          </Row>
          <Button type="primary" className="m-0" onClick={this.onSubmit}>
            Add Fields
          </Button>
        </div>
      </div>
    );
  }
}

export default AddFields;
