import React, { useEffect, useState } from "react";
import { Button, Tooltip, Upload, message, Spin } from "antd";
import FileViewer from "react-file-viewer";
import axiosJSON from "../../../../../util/SetHeaderAPI";
// import AddResume from "../../../../../modals/Candidate/addResume";
// import UploadResumeFileViewer from "../../../../../modals/Candidate/uploadResumeFileViewer";
// import { CustomModal as Modal } from "../../../../../util/CustomModal";
import { UploadOutlined, CloseCircleOutlined } from "@ant-design/icons";

const Resume = (props) => {
  let onError;
  onError = (e) => {
    setResume(false);
  };
  const [loader, setLoader] = useState(false);
  const [filepath, setFilePath] = useState("");
  const [filetype, setFileType] = useState("");
  // const [candidateData, setCandidateData] = useState("");
  // const [resumeLoader, setResumeLoader] = useState(false);
  // const [resumeViewer, setResumeViewer] = useState(false);
  const [resume, setResume] = useState(false);
  const [resourceDoc, setResourceDoc] = useState(null);

  const headers = {
    "Access-Control-Allow-Origin": "*",
  };

  const fetchResume = () => {
    axiosJSON
      .get("api/candidate/" + props.id + "/resume/", { headers: headers })
      .then((res) => {
        const body = res.data.data[0];
        if (body) {
          setFilePath(body.file);
          setFileType(body.file_ext);
          setResume(true);
        }
      });
  };
  // const handleShow = (flag) => {
  //   setShow(!flag);
  // };

  // const changeResumeLoader = (flag) => {
  //   setResumeLoader(flag);
  // };
  // const handleClose = () => {
  //   fetchResume();
  //   setShow(false);
  //   setResumeViewer(false);
  // };

  // const setResumeData = (data) => {
  //   setCandidateData(data);
  //   setResumeViewer(true);
  // };
  const customRequest = (file) => {
    console.log(file);
  };
  const handleFileChange = (info) => {
    setResourceDoc(info.file);
  };

  const onCancel = () => {
    setResourceDoc(null);
  };

  const editCandidate = () => {
    setLoader(true);
    let formData = new FormData();
    // data
    formData.append("file", resourceDoc.originFileObj);
    formData.append("name", props.data.name);
    formData.append("email", props.data.email);

    axiosJSON
      .put("api/candidate/" + props.id + "/", formData)
      .then(({ data, status }) => {
        message.success(data.message);
        props.update();
        setLoader(false);
      })
      .catch(function (error) {
        message.error(error.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    if (filepath === "" && filetype === "" && !resume) {
      fetchResume();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editCandidate]);

  return (
    <Spin spinning={loader}>
      <div key="resume">
        {resume ? (
          <FileViewer
            fileType={filetype}
            filePath={filepath}
            errorComponent={<div>Error</div>}
            onError={onError}
          />
        ) : (
          <div>
            <h2>No Resume Present !!</h2>
          </div>
        )}

        <Upload
          accept="application/pdf,.docx,image/jpeg,image/png"
          onChange={(f) => handleFileChange(f)}
          multiple={false}
          customRequest={(f) => customRequest(f)}
          showUploadList={false}
        >
          <Tooltip title="Upload Document">
            <div>
              <Button
                className="uploadbtn"
                style={{ background: "transparent", color: "#6B6E70" }}
                icon={<UploadOutlined />}
              >
                Upload Document
              </Button>
            </div>
          </Tooltip>
        </Upload>


        
        <div className="row">
          {resourceDoc !== null ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "70%",
                padding: "5px",
              }}
            >
              <div>
                <label style={{ fontWeight: "bold" }}>Selected File</label>
                <div className="text-file-attached ">
                  <span className="mb-2 ml-0">
                    {resourceDoc.name}
                    <CloseCircleOutlined
                      className="deleteicon"
                      style={{
                        color: "red",
                        fontSize: 14,
                        marginLeft: "10px",
                        borderWidth: "0px",
                        borderColor: "white",
                        float: "revert",
                        marginTop: "0px",
                      }}
                      onClick={onCancel}
                    />
                  </span>
                </div>
              </div>
              <div>
                <Button onClick={editCandidate}>Upload</Button>
              </div>
            </div>
          ) : null}
        </div>


{/* <Button onClick={() => handleShow(showMore)}> Upload Resume </Button> */}
        {/* {showMore && (
        <Modal
          title={null}
          className="upload-popup"
          visible={showMore}
          footer={null}
          onCancel={() => setShow(false)}
        >
          <AddResume
            id={props.id}
            resumeLoader={resumeLoader}
            setResumeData={setResumeData}
            changeResumeLoader={changeResumeLoader}
            closeModal={handleClose}
          />
        </Modal>
      )} */}
        {/* {resumeViewer && (
        <Modal
          title={null}
          className="upload-popup"
          closable={false}
          visible={resumeViewer}
          footer={null}
          onCancel={handleClose}
        >
          <UploadResumeFileViewer
            candidateData={candidateData}
            filePath={filepath}
            fileType={filetype}
            resume={resume}
            id={props.id}
            closeModal={handleClose}
          />
        </Modal>
      )} */}
      </div>
    </Spin>
  );
};

export default Resume;
